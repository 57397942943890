/* Layouts: Nav
--------------------------------------------------------------------------------------------------------------------- */

/* Animation Menú */

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }

  50% {
    top: 8px;
    transform: rotate(0);
  }

  100% {
    top: 8px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 8px;
    transform: rotate(45deg);
  }

  50% {
    top: 8px;
    transform: rotate(0deg);
  }

  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }

  50% {
    bottom: 6px;
    transform: rotate(0);
  }

  100% {
    bottom: 6px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 6px;
    transform: rotate(135deg);
  }

  50% {
    bottom: 6px;
    transform: rotate(0);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

/* Layouts: Nav
--------------------------------------------------------------------------------------------------------------------- */
//  SCSS Variables
$prefix: '.mm-ocd';

/** Animation duration. */
$mmspn_transition_duration: 0.3s;

/** Arrow size. */
$mmspn_arrow_size: 8px !default;

/** Arrow weight. */
$mmspn_arrow_weight: 2px !default;

/** Border opacity. */
$mmspn_border_opac: 0.15 !default;

/** Navbar opacity. */
$mmspn_navbar_opac: 0.4 !default;

/** Arrow opacity. */
$mmspn_arrow_opac: 0.4 !default;

/** Item height. */
$mmspn_item_height: 50px !default;

/** Item indent. */
$mmspn_item_indent: 20px !default;

/** Line height. */
$mmspn_line_height: 24px !default;

/** Panel offset. */
$mmspn_panel_offset: 30% !default;

$mmocd_width: 100% !default;
$mmocd_min_width: 200px !default;
$mmocd_max_width: 600px !default;

$mmocd_transition_duration: 0.3s !default;

:root {
  /** Height for menu items. */
  --mm-spn-item-height: #{$mmspn_item_height};

  /** Indent for menu items. */
  --mm-spn-item-indent: #{$mmspn_item_indent};

  /** Line height for menu items. */
  --mm-spn-line-height: #{$mmspn_line_height};

  /** Width for the drawer. */
  --mm-ocd-width: #{$mmocd_width};

  /** Min-width for the drawer. */
  --mm-ocd-min-width: #{$mmocd_min_width};

  /** Max-width for the drawer. */
  --mm-ocd-max-width: #{$mmocd_max_width};
}

//  The rest of the page
body#{$prefix}-opened {
  //  Somewhat prevents scrolling.
  overflow-y: hidden;
  overscroll-behavior: none;
}

//  The wrapper
.mm-ocd {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;
  z-index: 999;
  overflow: hidden;
  overscroll-behavior: contain;
  background: rgba(#000, 0);
  transition: {
    property: bottom, background-color;
    duration: 0s, $mmocd_transition_duration;
    timing-function: ease;
    delay: $mmocd_transition_duration * 1.5, $mmocd_transition_duration * 0.5;
  }

  .mm-spn--navbar {
    &::after {
      content: '< ' attr(data-mm-spn-title);
      display: none;
      position: fixed;
      top: 160px;
      right: 0;
      height: 25px;
      padding: 0 calc(var(--mm-spn-item-indent) * 2);
      padding: 0 0 0 40px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      left: 0;
      font-size: 18px;
      cursor: pointer;
      z-index: 4;
      color: $white;
      font-weight: 500;
      text-transform: uppercase;

      @include transition(all 0.3s ease);

      @include responsive(bigmovil) {
        padding: 0 0 0 20px;
      }
    }

    &[data-mm-spn-title="Menu"] {
      &::after {
        opacity: 0;
        left: 100%;
      }
    }
  }

  &--open {
    top: 0;
    bottom: 0;
    background: rgba(#000, 0.8);
    transition-delay: 0s;

    ul li {
      height: auto;

      @include responsive(portatil) {
        margin: 6.25px 25px;
        padding: 0;
      }

      @include responsive(pc) {
        padding-left: 0;
        padding-right: 0;
      }

      @include responsive(movil) {
        margin: 0;
        padding: 6.25px 0;
      }

      &.first {
        margin-top: 25px;

        & + .first {
          margin-top: 0;
        }

        a::before {
          content: '';
          display: flex;
          height: 4px;
          background-color: #e0e0e0;
          position: absolute;
          top: -4px;
          left: -10%;
          width: 120%;
        }
      }

      &:nth-child(2) {
        @include responsive(pantalla) {
          padding-bottom: 12.5px;
        }
      }

      &.last {
        &::after {
          opacity: 0;
        }
      }

      &:last-child {
        @include responsive(pantalla) {
          padding-bottom: 12.5px;
        }

        &::after {
          border: 0;
        }
      }

      &:not(.menu-item-has-children) {
        &::before {
          display: none;
        }
      }

      &.header__menu-principal-item-borderbottom {
        padding: 6.25px 0 19px;
        margin: 6.25px 0;

        @include responsive(portatil) {
          padding: 6.25px 25px;
        }

        @include responsive (pantalla) {
          padding: 6.25px 25px;
        }

        @include responsive (movil) {
          padding: 6.25px 0 12.5px;
        }
      }

      &.header__menu-principal-item-background {
        @include responsive(portatil) {
          display: block;
          background-color: $primary;
          padding-left: 25px;
          margin: 0;
          margin-left: 0;
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
        }

        @include responsive(movil) {
          padding-left: 12.5px;
          margin-left: 0;
        }

        span {
          a {
            @include responsive(portatil) {
              padding-left: 31.25px;
              color: $white;
              text-transform: capitalize;
            }
          }
        }
      }

      &.header__menu-principal-item-background2 {
        @include responsive(portatil) {
          background-color: $secondary;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: absolute;
          bottom: 50px;
          left: 0;
          width: 100%;
        }

        span {
          a {
            @include responsive(portatil) {
              color: $black;
              padding-left: 35px;
            }
          }

          i {
            &.i__custom {
              @include responsive(portatil) {
                position: initial;
                right: initial;
                top: initial;
                font-size: 16px;
                color: black;
                display: inline-block;
                margin-right: -12.5px;
              }
            }

            &.i__left {
              @include responsive(portatil) {
                right: initial;
                left: 0;
                top: initial;
                font-size: 16px;
                color: black;
                display: inline-block;
                margin-left: 25px;
              }

              @include responsive(pc) {
                margin-left: 2px;
              }

              @include responsive(movil) {
                margin-left: -8px;
                left: 10px;
              }
            }
          }
        }
      }

      .submenu__item {
        @include responsive(portatil) {
          padding: 0;
          padding-bottom: 0;
          margin: 0;
        }

        a {
          @include responsive(portatil) {
            color: $black;
          }
        }
      }
    }
  }
}

//  The content
#{$prefix}__content {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: $mmocd_width; // IE11 fallback
  width: var(--mm-ocd-width);
  min-width: $mmocd_min_width; // IE11 fallback
  min-width: var(--mm-ocd-min-width);
  max-width: $mmocd_max_width; // IE11 fallback
  max-width: var(--mm-ocd-max-width);
  backdrop-filter: saturate(180%) blur(30px);
  background: rgba(245, 245, 245, 0.95);
  transition-property: background-color, backdrop-filter, -webkit-backdrop-filter;
  padding-top: 110px;

  @include responsive(portatil) {
    padding-top: 90px;
  }

  transition: {
    property: transform;
    duration: $mmocd_transition_duration;
    timing-function: ease;
  }

  #{$prefix}--left & {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  #{$prefix}--right & {
    right: 0;
    transform: translate3d(100%, 0, 0);
  }

  #{$prefix}--open & {
    transform: translate3d(0, 0, 0);
  }
}

//  The backdrop
#{$prefix}__backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  width: calc(100% - #{$mmocd_width}); // IE11 fallback
  width: calc(100% - var(--mm-ocd-width));
  min-width: calc(100% - #{$mmocd_max_width}); // IE11 fallback
  min-width: calc(100% - var(--mm-ocd-max-width));
  max-width: calc(100% - #{$mmocd_min_width}); // IE11 fallback
  max-width: calc(100% - var(--mm-ocd-min-width));
  background: rgba(3, 2, 1, 0);

  #{$prefix}--left & {
    right: 0;
  }

  #{$prefix}--right & {
    left: 0;
  }
}

$prefix: '.mm-spn';

/** Animation duration. */
$mmspn_transition_duration: 0.3s;

/** Arrow size. */
$mmspn_arrow_size: 10px !default;

/** Arrow weight. */
$mmspn_arrow_weight: 2px !default;

/** Border opacity. */
$mmspn_border_opac: 0.15 !default;

/** Navbar opacity. */
$mmspn_navbar_opac: 0.4 !default;

/** Arrow opacity. */
$mmspn_arrow_opac: 0.4 !default;

/** Item height. */
$mmspn_item_height: 50px !default;

/** Item indent. */
$mmspn_item_indent: 20px !default;

/** Line height. */
$mmspn_line_height: 24px !default;

/** Panel offset. */
$mmspn_panel_offset: 30% !default;

%mmspn-reset {
  display: block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#{$prefix} {
  @extend %mmspn-reset;

  width: 100%;
  height: 100%;

  //  Prevent the entire menu from highlighting when clicking the navbar
  -webkit-tap-highlight-color: transparent;

  //  Enables smooth scrolling.
  -webkit-overflow-scrolling: touch;

  //  Prevent any content being visible outside the menu
  overflow: hidden;
  clip-path: inset(0 0 0 0);

  //  This creates a new viewport from the menu so the panels can be fixed
  transform: translateX(0);

  //  All listviews.
  ul {
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    left: 100%;
    bottom: 0;
    z-index: 2; // Needs a z-index to be above its parent.
    width: 100%;
    line-height: $mmspn_line_height; // IE11 fallback
    line-height: var(--mm-spn-line-height);
    overflow: visible;
    overflow-y: auto;
    background: inherit;
    transition: left $mmspn_transition_duration ease 0s;
    cursor: default;

    @extend %mmspn-reset;

    //  Because padding-bottom doesn't work if it is scrollable.
    &::after {
      content: '';
      display: none;
      height: $mmspn_item_height; // IE11 fallback
      height: var(--mm-spn-item-height);
    }

    &.header__corporate {
      display: none;
    }
  }

  //  First level listview.
  > ul {
    left: 0;
  }

  //  Listview opened.
  ul#{$prefix}--open {
    left: 0;
  }

  //  Child listview opened.
  ul#{$prefix}--parent {
    left: -$mmspn_panel_offset;
    left: -100%;
    overflow-y: hidden;
  }

  //  Listitems
  li {
    position: relative;
    background: inherit;
    cursor: pointer;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @extend %mmspn-reset;

    &.submenu {
      &::after {
        content: "\e904";
        font-family: $icon;
        position: absolute;
        width: calc(100% - 40px);
        height: 100%;
        z-index: 100;
        margin-left: $mmspn_item_indent; // IE11 fallback
        margin-left: var(--mm-spn-item-indent);
        margin-right: 20px;
        font-size: 19px;
        align-content: center;
        color: $primary;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transform-origin: 100%;

        //@include transform(rotate(90deg));
      }
    }

    &.only-mobile {
      display: none;

      @include responsive(portatil) {
        display: flex;
      }
    }

    &.header__nav--shop-nav-corporate {
      flex-direction: column;
      align-items: flex-start;

      a {
        font-weight: 400;

        &.contact-info {
          padding-bottom: 0;
        }

        &::after {
          background-color: transparent;
        }
      }

      span {
        a {
          padding: 2px 20px;
          font-size: 13px;
          font-weight: 400;

          &::after {
            background-color: transparent;
          }
        }
      }
    }

    &.header__nav--shop-nav-blog {
      a {
        font-weight: 400;

        span {
          font-weight: 400;
        }

        &::after {
          background-color: transparent;
        }
      }
    }

    &.header__nav--shop-nav-user {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      a {
        background: white;
        padding: 14px 0;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: 16px;

        &::after {
          background-color: transparent;
        }
      }
    }
  }

  .subpanel,
  .subpanel-sub {
    padding-top: var(--mm-spn-line-height);

    li {
      &.viewall {
        a,
        span {
          font-size: 13px;
          font-weight: 400;
        }
      }

      &:first-child {
        position: relative;

        a {
          &::before {
            content: '';
            width: calc(100% - 40px);
            height: 1px;
            opacity: $mmspn_border_opac;
            position: absolute;
            top: 0;
            left: 20px;
            right: 20px;
          }
        }
      }

      &:last-child {
        border: 0;
      }
    }
  }

  a {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: $primary;
    background: transparent;
    text-decoration: none;
    text-transform: lowercase;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: (($mmspn_item_height - $mmspn_line_height) / 2) $mmspn_item_indent; // IE11 fallback
    padding: calc(( var(--mm-spn-item-height) - var(--mm-spn-line-height)) / 2) var(--mm-spn-item-indent);

    &::first-letter {
      text-transform: capitalize;
    }

    span {
      background: transparent;
      font-size: 15px;
      font-weight: 500;
      color: $primary;
      text-transform: lowercase;
      display: inline-block;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    @extend %mmspn-reset;

    //  Border on the right.
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 20px;
      bottom: 0;
      opacity: $mmspn_border_opac;
      width: calc(100% - 40px);
      height: 1px;
    }

    img {
      width: 22px;
      height: 23px;
      object-fit: contain;
      margin-right: 15px;
    }
  }
}
