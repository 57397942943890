/* Common: Mixins By Numéricco
--------------------------------------------------------------------------------------------------------------------- */

@mixin rounded($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin shadow($x, $y, $blur, $color) {
  -webkit-box-shadow: $x $y $blur $color;
  -moz-box-shadow: $x $y $blur $color;
  box-shadow: $x $y $blur $color;
}

@mixin text-shadow($x, $y, $blur, $color) {
  -webkit-text-shadow: $x $y $blur $color;
  -moz-text-shadow: $x $y $blur $color;
  text-shadow: $x $y $blur $color;
}

@mixin shadowall($shadow) {
  -webkit-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin gradient($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  // Safari 5.1-6, Chrome 10+
  background-image: -webkit-linear-gradient(bottom, $start-color, $end-color);

  // Opera 12
  background-image: -o-linear-gradient(bottom, $start-color, $end-color);

  // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-image: linear-gradient(to top, $start-color, $end-color);
  background-repeat: repeat-x;

  // IE9 and down
  filter:
    progid:dximagetransform.microsoft.gradient(
      startColorstr='#{ie-hex-str($start-color)}',
      endColorstr='#{ie-hex-str($end-color)}',
      GradientType=1
    );
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;

  // Safari 5.1-6, Chrome 10+
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color);

  // Opera 12
  background-image: -o-linear-gradient($deg, $start-color, $end-color);

  // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-image: linear-gradient($deg, $start-color, $end-color);
}

@mixin apearance($apearance) {
  -webkit-appearance: $apearance;
  -o-appearance: $apearance;
  -ms-appearance: $apearance;
  -moz-appearance: $apearance;
  appearance: $apearance;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin transform-style($preserve) {
  -webkit-transform-style: $preserve;
  -moz-transform-style: $preserve;
  -ms-transform-style: $preserve;
  transform-style: $preserve;
}

@mixin transition($transitions) {
  -webkit-transition: $transitions;
  -moz-transition: $transitions;
  -ms-transform: $transitions;
  -o-transition: $transitions;
  transition: $transitions;
}

@mixin animation($animations) {
  -webkit-animation: $animations;
  -moz-animation: $animations;
  -ms-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}

@mixin animation-duration($animation) {
  -webkit-animation-duration: $animation;
  -moz-animation-duration: $animation;
  -ms-animation-duration: $animation;
  -o-animation-duration: $animation;
  animation-duration: $animation;
}

@mixin animation-iteration-count($animation) {
  -webkit-animation-iteration-count: $animation;
  -moz-animation-iteration-count: $animation;
  -ms-animation-iteration-count: $animation;
  -o-animation-iteration-count: $animation;
  animation-iteration-count: $animation;
}

@mixin animation-fill-mode($animation) {
  -webkit-animation-fill-mode: $animation;
  -moz-animation-fill-mode: $animation;
  -ms-animation-fill-mode: $animation;
  -o-animation-fill-mode: $animation;
  animation-fill-mode: $animation;
}

@mixin animation-timing-function($animation) {
  -webkit-animation-timing-function: $animation;
  -moz-animation-timing-function: $animation;
  -ms-animation-timing-function: $animation;
  -o-animation-timing-function: $animation;
  animation-timing-function: $animation;
}

@mixin animation-name($animation) {
  -webkit-animation-name: $animation;
  -moz-animation-name: $animation;
  -ms-animation-name: $animation;
  -o-animation-name: $animation;
  animation-name: $animation;
}

@mixin animation-delay($animation) {
  -webkit-animation-delay: $animation;
  -moz-animation-delay: $animation;
  -ms-animation-delay: $animation;
  -o-animation-delay: $animation;
  animation-delay: $animation;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;

  //IE8
  filter: alpha(opacity=$opacity-ie);
}

@mixin placeholder() {
  ::-webkit-input-placeholder { @content; }
  ::-moz-placeholder { @content; }
  :-moz-placeholder { @content; }
  :-ms-input-placeholder { @content; }
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin responsive($media) {
  @if $media == maxpantallaca {
    @media only screen and (min-width: $milseiscientos) {
      @content;
    }
  }

  @else if $media == milseiscientos {
    @media only screen and (max-width: $milseiscientos) {
      @content;
    }
  }

  @else if $media == milcuatrocientos {
    @media only screen and (max-width: $milcuatrocientos) {
      @content;
    }
  }

  @else if $media == pantallaca {
    @media only screen and (max-width: $pantallaca) {
      @content;
    }
  }

  @else if $media == minportatil {
    @media only screen and (min-width: $portatil) {
      @content;
    }
  }

  @else if $media == portatil {
    @media only screen and (max-width: $portatil) {
      @content;
    }
  }

  @else if $media == minpantalla {
    @media only screen and (min-width: $pantalla) {
      @content;
    }
  }

  @else if $media == pantalla {
    @media only screen and (max-width: $pantalla) {
      @content;
    }
  }

  @else if $media == minpantallamin {
    @media only screen and (min-width: $pantallamin) {
      @content;
    }
  }

  @else if $media == pantallamin {
    @media only screen and (max-width: $pantallamin) {
      @content;
    }
  }

  @else if $media == minpc {
    @media only screen and (min-width: $pc) {
      @content;
    }
  }

  @else if $media == pc {
    @media only screen and (max-width: $pc) {
      @content;
    }
  }

  @else if $media == mintablet {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  }

  @else if $media == tablet {
    @media only screen and (max-width: $tablet) {
      @content;
    }
  }

  @else if $media == minbigmovil {
    @media only screen and (min-width: $bigmovil) {
      @content;
    }
  }

  @else if $media == bigmovil {
    @media only screen and (max-width: $bigmovil) {
      @content;
    }
  }

  @else if $media == minmovil {
    @media only screen and (min-width: $movil) {
      @content;
    }
  }

  @else if $media == movil {
    @media only screen and (max-width: $movil) {
      @content;
    }
  }

  @else if $media == minminimovil {
    @media only screen and (min-width: $minimovil) {
      @content;
    }
  }

  @else if $media == minimovil {
    @media only screen and (max-width: $minimovil) {
      @content;
    }
  }
}

@mixin nomobile() {
  @include responsive(movil) {
    display: none;
  }

  @include responsive(tablet) {
    display: block;
  }
}

@mixin notablet() {
  @include responsive(movil) {
    display: none;
  }

  @include responsive(tablet) {
    display: none;
  }

  @include responsive(ordenador) {
    display: block;
  }
}

@mixin noordenador() {
  @include responsive(movil) {
    display: none;
  }

  @include responsive(tablet) {
    display: none;
  }

  @include responsive(ordenador) {
    display: none;
  }

  @include responsive(pantalla) {
    display: block;
  }
}

@mixin viewport-unit($property, $value) {
  #{$property}: $value;
  $unit: unit($value);

  @if (index((vw, vh, vmin, vmax, calcvh), $unit) != null) {
    $devices: (
      (768px, 1024px), // iPad (all versions)
      (320px, 480px), // iPhone 4
      (320px, 568px), // iPhone 5, 5C, 5S
      (375px, 667px), // iPhone 6, 6s, 7, 8
      (414px, 736px), // iPhone 6+, 6s+, 7+, 8+
      (375px, 812px), // iPhone X, Xs
      (414px, 896px), // iPhone Xr
    );

    @each $device in $devices {
      $device-width: nth($device, 1);
      $device-height: nth($device, 2);
      $device-query: "only screen and (-webkit-min-device-pixel-ratio: 1)";
      $device-query: "#{$device-query} and (device-width: #{$device-width})";
      $device-query: "#{$device-query} and (device-height: #{$device-height})";

      // See https://github.com/sass/sass/issues/533
      $percent: $value / ($value * 0 + 1);
      $percent-width: $device-width * $percent / 100;
      $percent-height: $device-height * $percent / 100;

      @if ($unit == vmin or $unit == vmax) {
        @media #{$device-query} {
          #{$property}: if($unit == vmin, $percent-width, $percent-height);
        }
      }

      @else {
        @media #{$device-query} and (orientation: portrait) {
          #{$property}: if($unit == vw, $percent-width, $percent-height);
        }

        @media #{$device-query} and (orientation: landscape) {
          #{$property}: if($unit == vw, $percent-height, $percent-width);
        }
      }
    }
  }
}
