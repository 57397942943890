/* Layouts: Product Slider
--------------------------------------------------------------------------------------------------------------------- */
.home,
.product-page {
  &__products {
    margin: 100px 50px;
    width: calc(100% - 100px);

    @include responsive(pantallamin) {
      width: calc(100% - 50px);
      margin: 50px 25px;
    }

    @include responsive(tablet) {
      width: calc(100% - 50px);
      margin: 50px 25px;
    }

    @include responsive(movil) {
      width: calc(100% - 25px);
      margin: 50px 12.5px;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-text {
        max-width: 25%;
        margin-bottom: 25px;

        @include responsive(milseiscientos) {
          max-width: 35%;
        }

        @include responsive(pantallamin) {
          max-width: 100%;
        }

        .title {
          @include responsive(movil) {
            font-size: 25px;
          }
        }
      }

      .btn {
        @include responsive(pantallamin) {
          display: none;
        }
      }
    }

    &-nav {
      display: flex;
      align-items: center;
      margin-top: 25px;

      @include responsive(movil) {
        margin-top: 12.5px;
      }

      div {
        i {
          display: inline-block;
          border-radius: 100px;
          padding: 4px;

          &:hover {
            @include responsive(minpc) {
              background: $primary;
              color: $white;
              cursor: pointer;

              @include transition(all 0.45s ease-in);
            }
          }
        }

        &:first-child {
          i {
            transform: rotate(180deg);
            margin-right: 10px;
          }
        }
      }
    }

    &-top {
      margin: 100px 50px 0;

      @include responsive(pantallamin) {
        margin: 50px 25px 0;
      }

      @include responsive(pantallamin) {
        margin: 50px 12.5px 0;
      }
    }

    .tns-inner.tns-inner {
      margin: 0;

      @include responsive(minpc) {
        margin: 0;
      }
    }
  }
}

.product-page__news-slider-nav {
  margin-top: 25px;
  margin-left: 50px;

  @include responsive(tablet) {
    margin-left: 12.5px;
  }
}
