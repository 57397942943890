/* Common: Grid
--------------------------------------------------------------------------------------------------------------------- */

.container {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;

  @include responsive(pc) {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.section-productos {
    overflow: scroll;
  }

  &--mobilecustom {
    @include responsive(movil) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.container-medio {
  width: 100%;
  max-width: calc(1560px);
  margin: 0 auto;
  padding-left: 8%;
  padding-right: 8%;
  overflow: hidden;

  @include responsive("pc") {
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.container-mini {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
  padding-left: 13%;
  padding-right: 13%;
  overflow: hidden;

  @include responsive(pc) {
    max-width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  @include responsive(tablet) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container-post {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-left: 140px;
  padding-right: 140px;
  overflow: hidden;

  @include responsive(pc) {
    max-width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  @include responsive(tablet) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container-absoluto {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 5;

  @include responsive(pantalla) {
    top: 43px;
  }

  @include responsive(pc) {
    top: 0;
  }
}

.container-footer {
  padding-left: 0;
  padding-right: 0;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  clear: both;

  @include responsive(pc) {
    margin: 0;
  }
}

.col {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @include responsive(bigmovil) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.col100 {
    width: 100%;
  }

  &.col80 {
    width: 80%;
  }

  &.col75 {
    width: 75%;
  }

  &.col66 {
    width: 66.6666%;
  }

  &.col60 {
    width: 60%;
  }

  &.col55 {
    width: 55%;
  }

  &.col50 {
    width: 50%;
  }

  &.col40 {
    width: 40%;
  }

  &.col33 {
    width: 33.3333%;
  }

  &.col30 {
    width: 30%;
  }

  &.col25 {
    width: 25%;
  }

  &.col20 {
    width: 20%;
  }

  &.col15 {
    width: 15%;
  }

  &.col100,
  &.col75,
  &.col66,
  &.col60,
  &.col50,
  &.col40,
  &.col33,
  &.col25,
  &.col20,
  &.col30,
  &.col15,
  &.col55 {
    @include responsive(tablet) {
      width: 100%;
    }
  }

  &.colm50 {
    @include responsive(tablet) {
      width: 50%;
    }
  }

  &.colm33 {
    @include responsive(tablet) {
      width: 33%;
    }
  }

  &.colpc100 {
    @include responsive(pc) {
      width: 100%;
    }
  }
}
