/* Common: Fonts
--------------------------------------------------------------------------------------------------------------------- */
$font-text: 'Montserrat', sans-serif;
$font-title: 'Montserrat', sans-serif;
$icon: 'icomoon' !important;

@font-face {
  font-family: 'icomoon';
  src: url('/themes/granvelada/assets/css/fonts/icomoon.eot?f9c2lr');
  src:
    url('/themes/granvelada/assets/css/fonts/icomoon.eot?f9c2lr#iefix') format('embedded-opentype'),
    url('/themes/granvelada/assets/css/fonts/icomoon.ttf?f9c2lr') format('truetype'),
    url('/themes/granvelada/assets/css/fonts/icomoon.woff?f9c2lr') format('woff'),
    url('/themes/granvelada/assets/css/fonts/icomoon.svg?f9c2lr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right::before {
  content: "\e900";
}

.icon-box::before {
  content: "\e901";
}

.icon-close::before {
  content: "\e902";
}

.icon-dollar-hand::before {
  content: "\e903";
}

.icon-dots::before {
  content: "\e904";
}

.icon-download::before {
  content: "\e905";
}

.icon-facebook-circle::before {
  content: "\e906";
}

.icon-facebook::before {
  content: "\e907";
}

.icon-go-right::before {
  content: "\e908";
}

.icon-heart::before {
  content: "\e909";
}

.icon-heart-hover::before {
  content: "\e922";
}

.icon-instagram-circle::before {
  content: "\e90a";
}

.icon-instagram::before {
  content: "\e90b";
}

.icon-mastercard::before {
  content: "\e90c";
}

.icon-paper-plane::before {
  content: "\e90d";
}

.icon-paypal::before {
  content: "\e90e";
}

.icon-phone::before {
  content: "\e90f";
}

.icon-pinterest-circle::before {
  content: "\e910";
}

.icon-pinterest::before {
  content: "\e911";
}

.icon-play::before {
  content: "\e912";
}

.icon-safe::before {
  content: "\e913";
}

.icon-search::before {
  content: "\e914";
}

.icon-share::before {
  content: "\e915";
}

.icon-shopping-bag::before {
  content: "\e916";
}

.icon-star::before {
  content: "\e917";
}

.icon-tiktok-circle::before {
  content: "\e918";
}

.icon-tiktok::before {
  content: "\e919";
}

.icon-transform::before {
  content: "\e91a";
}

.icon-truck::before {
  content: "\e91b";
}

.icon-two-arrows::before {
  content: "\e91c";
}

.icon-user::before {
  content: "\e91d";
}

.icon-visa::before {
  content: "\e91e";
}

.icon-youtube-circle::before {
  content: "\e91f";
}

.icon-youtube::before {
  content: "\e920";
}

.icon-full-screen::before {
  content: "\e921";
}

.icon-rotate::before {
  content: "\e923";
}

.icon-chat::before {
  content: "\e924";
}

.icon-place::before {
  content: "\e925";
}
