/* Layouts: Cart
--------------------------------------------------------------------------------------------------------------------- */

.page-cart {
  .content__main {
    width: 80%;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: initial;

    @include responsive(pc) {
      width: 100%;
      margin: 25px auto;
      padding: 0 12.5px;
      overflow: hidden;
    }
  }

  main {
    overflow: initial;
  }

  #main {
    width: calc(70% - 50px);
    margin-right: 50px;
    overflow: initial;

    @include responsive(pc) {
      width: 100%;
      margin-right: 0;
    }

    .cart-grid {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-top: 50px;

      @include responsive(tablet) {
        margin-top: 0;
      }

      .cart-container {
        .card-header {
          font-size: 30px;
          font-family: $font-text;
          font-weight: 500;
          letter-spacing: 1px;
          text-transform: initial;
        }

        .card-total {
          font-weight: 600;

          &__number {
            font-weight: 400;
          }

          &__price {
            font-weight: 600;
          }
        }

        .card-body {
          .cart-overview {
            .cart-items {
              .product-line-grid {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                border: 1px solid $secondary;
                padding: 12.5px 25px;
                margin: 25px 0;
                position: relative;

                @include responsive(pc) {
                  padding: 12.5px;
                  margin: 12.5px 0;
                }

                .media {
                  display: flex;
                  align-items: center;

                  img {
                    width: 70px;
                    height: 70px;

                    @include responsive(pc) {
                      width: 50px;
                      height: 50px;
                    }
                  }

                  .product-line {
                    &__title {
                      text-transform: initial;
                      font-size: 15px;
                      margin-bottom: 10px;
                      display: block;
                    }

                    &__body {
                      margin: 0 0 0 50px;

                      @include responsive(pc) {
                        margin: 0 0 0 12.5px;
                      }

                      .cnt-small {
                        display: flex;
                        align-items: center;

                        .small {
                          margin-right: 10px;
                          font-size: 12px;

                          & > span {
                            &:first-child {
                              font-weight: 600;
                            }
                          }
                        }
                      }

                      .product-line-actions {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;

                        .js-cart-line-product-quantity {
                          border: 1px solid $secondary;
                          margin-left: 0;
                          margin-top: 10px;
                          padding: 5px;
                        }
                      }
                    }
                  }
                }

                &-right {
                  .cart-line-product-actions {
                    position: absolute;
                    right: 25px;
                    bottom: 12.5px;

                    @include responsive(pc) {
                      right: 12.5px;
                    }

                    .remove-from-cart {
                      i {
                        font-style: normal;
                        text-decoration: underline;
                        font-size: 12px;
                      }
                    }
                  }

                  .input-group {
                    &-prepend {
                      display: none;
                    }

                    &-append {
                      display: none;
                    }
                  }
                }

                .product-line-info {
                  display: flex;
                  align-items: center;
                  max-width: 100px;
                  flex-wrap: wrap;

                  .current-price {
                    display: flex;
                    justify-content: flex-end;

                    &.product--discount {
                      color: #fa7272;
                    }
                  }

                  .product-discount {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .regular-price {
                      text-decoration: line-through;
                      color: $light-grey;
                      font-weight: 600;
                    }

                    .discount-percentage {
                      background-color: $mid-grey;
                      color: $white;
                      margin: 0 5px;
                      padding: 2.5px 5px;
                      font-weight: 500;
                    }
                  }
                }

                .wishlist {
                  display: none;
                }
              }
            }
          }
        }
      }

      &-body {
        width: 100%;

        .btn--primary {
          display: inline-block;
          margin-top: 25px;
        }

        .btn--primary-swapped {
          margin: 15px 0;
          font-size: 12px;

          @include responsive(pc) {
            width: 100%;
            text-align: center;
          }
        }
      }

      .row {
        margin: 0;
      }

      hr {
        display: none;
      }
    }
  }

  .cart {
    &__products {
      margin: 50px 0;

      @include responsive(pc) {
        margin: 0;
        width: 100%;
      }

      &-info {
        &-text {
          .title {
            font-size: 25px;
            margin: 25px 0 12.5px;
          }
        }
      }

      &-slider-nav {
        display: flex;
        align-items: center;
        margin-top: 12.5px;

        div {
          margin-right: 10px;

          &:first-child {
            i {
              display: inline-block;
              transform: rotate(-180deg);
            }
          }

          i {
            padding: 4px;
            border-radius: 100px;
            display: inline-block;

            @include transition (all 0.45s ease-in);

            &:hover {
              @include responsive(minpc) {
                cursor: pointer;
                color: $white;
                background-color: $primary;

                @include transition (all 0.45s ease-in);
              }
            }
          }
        }
      }

      .product--image {
        max-height: 240px;
        object-position: center;
      }
    }
  }

  .cart__products--gift {
    background-color: $secondary;
    padding: 25px;

    @include responsive(pc) {
      margin-top: 25px;
      padding: 25px 12.5px;
      width: 100%;
    }

    .cart {
      &__products {
        &-info {
          &-text {
            .title {
              margin-left: 12.5px;
              margin-top: 0;
              font-weight: 600;
              font-size: 16px;

              @include responsive(pc) {
                margin-left: 0;
              }
            }

            .text {
              margin-left: 12.5px;

              @include responsive(pc) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .text-gift {
      color: $primary;
      font-weight: 600;
      font-size: 12px;
    }

    &-nav {
      margin-left: 12.5px;
      display: flex;
      align-items: center;

      @include responsive(pc) {
        margin-left: 0;
      }

      div {
        &:first-child {
          i {
            display: inline-block;
            transform: rotate(-180deg);
          }
        }

        i {
          margin-right: 10px;
          padding: 4px;
          border-radius: 100px;

          @include transition(all 0.45s ease-in);

          &:hover {
            @include responsive(minpc) {
              cursor: pointer;
              color: $white !important;
              background-color: $primary;

              @include transition(all 0.45s ease-in);
            }
          }
        }
      }
    }

    &-slider {
      .product {
        a {
          overflow: hidden;
        }

        &:hover {
          @include responsive(minpc) {
            .product--image {
              transform: scale(1.2);

              @include transition(all 0.45s ease-in);
            }
          }
        }
      }
    }
  }

  .cart-grid-right {
    width: calc(25% - 50px);
    right: 10%;
    bottom: 15%;
    padding: 25px;

    @include responsive(milseiscientos) {
      bottom: 0;
      padding: 0;
    }

    &--fixed {
      position: relative;
      top: 45px;
      right: 0;
      width: 30%;
      height: auto;
      margin: 0 0 40px;

      @include responsive(pc) {
        width: 100%;
        position: initial;
      }

      &-content {
        position: sticky;
        top: 0;
        padding-top: 0;

        @include transition(all 0.3s ease-in);

        @include responsive(pc) {
          position: initial;
        }

        .cart-summary {
          @include responsive(pc) {
            position: fixed;
            background: $white;
            width: 100%;
            left: 0;
            bottom: 0;
            z-index: 9999;
            padding: 12.5px;
            box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.15);
          }

          .card-body--summary {
            .summary-only-mobile {
              display: none;
              align-items: center;
              justify-content: space-between;
              margin-bottom: -12.5px;

              @include responsive(pc) {
                display: flex;
              }

              .text {
                margin: 0;
                font-weight: 600;
                color: $primary;
              }

              i {
                transform: rotate(-90deg);
                display: inline-block;
                font-size: 13px;
                color: $primary;
              }
            }

            &--closed {
              .cart-detailed-totals-details,
              .cart-summary-totals {
                @include responsive(portatil) {
                  display: none;
                }
              }
            }

            &--opened {
              .cart-detailed-totals-details,
              .cart-summary-totals {
                @include responsive(portatil) {
                  display: block;
                }
              }

              .summary-only-mobile {
                margin-bottom: 12.5px;

                i {
                  transform: rotate(90deg);
                }
              }
            }
          }

          button {
            color: $white;

            &:hover {
              @include responsive(minpc) {
                color: $primary;
              }
            }
          }
        }
      }
    }

    .cart-summary-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12.5px;

      a {
        @include responsive(pc) {
          font-size: 13px;
        }
      }

      .value {
        font-weight: 600;
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-top: 0;

      @include responsive(pc) {
        display: none;
      }
    }

    .cart-extra {
      margin: 25px 0;

      &__title {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        margin: 50px 0 25px;

        @include responsive(pc) {
          margin: 0 0 25px;
        }

        &-uppercase {
          text-transform: uppercase;
          margin: 25px 0 12.5px;
        }
      }

      &__list {
        li {
          margin: 12.5px 0;
          padding: 0;

          a {
            text-transform: initial;
            text-decoration: none;
            font-size: 13px;
          }
        }

        &-img {
          display: flex;
          align-items: center;

          li {
            margin: 0;
            padding: 0;

            img {
              width: 65px;
              margin-right: 15px;
            }
          }
        }
      }
    }

    .row {
      margin: 0;
    }

    hr {
      display: none;
    }

    .btn {
      &--primary {
        margin-top: 25px;
        width: 100%;
        display: block;
      }
    }
  }

  .block-promo {
    margin: 50px 0 0;

    @include responsive(pc) {
      margin: 25px 0 0;
    }

    .cart-voucher {
      .display-promo {
        font-size: 16px;
        font-weight: 600;
        margin-top: 0;
        color: $mid-grey;
      }

      .promo-code {
        &__content {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .form__add-voucher {
            @include responsive(pc) {
              width: 100%;
            }

            .input-group {
              display: flex;
              align-items: stretch;
              position: relative;
              min-width: 366px;

              input {
                border: 1px solid $mid-grey;
                width: 100%;
              }

              &-append {
                position: relative;
                right: 12.5px;
                top: calc(50% - 9px);

                button {
                  background-color: $primary;
                  padding: 12px 30px;
                  border: 1px solid $primary;
                  color: white;

                  &:hover {
                    @include responsive(minpc) {
                      border: 1px solid $primary;
                      background-color: transparent;
                      color: $primary;
                    }
                  }
                }
              }
            }
          }

          .promo-name {
            margin: 0 12.5px;

            @include responsive(pc) {
              margin: 12.5px 12.5px 12.5px 0;
            }

            li {
              padding-bottom: 0;

              &:nth-child(n+2) {
                display: none;
              }
            }
          }

          .promo-code-alert {
            width: 100%;

            span {
              padding-top: 5px;
              display: inline-block;
            }

            span:empty {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  .home__products {
    margin: 40px 50px;
  }
}

body.header-fixed.page-cart .cart-grid-right--fixed-content {
  padding-top: 150px;

  @include responsive(pc) {
    padding-top: 0;
  }
}

body.cart-empty {
  #main {
    width: calc(55% - 50px);
  }

  .home__products {
    @include responsive(pc) {
      margin: 40px 0;
    }

    .home__products-info-text {
      max-width: 100%;
    }
  }
}
