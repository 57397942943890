/* Common: Extends
--------------------------------------------------------------------------------------------------------------------- */

/* Breadcrumbs */
.breadcrumbs-list {
  display: block;
  flex-wrap: initial;
  align-items: initial;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 0;
  padding: 12.5px;
  list-style: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    display: inline-block;
    margin-right: 5px;
    padding-right: 5px;
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    list-style: none;
    padding-bottom: 0;
    text-transform: lowercase;

    @include responsive(pc) {
      display: inline-flex;
      width: auto;
      margin-right: 5px;
      padding-right: 5px;
    }

    &::first-letter {
      text-transform: capitalize;
    }

    &:last-child {
      border-right: none;
    }

    &::before {
      display: none;
    }

    a {
      text-transform: lowercase;
      font-weight: 400;
      opacity: 0.5;
      font-size: 11px;
      color: $dark-grey;

      &::first-letter {
        text-transform: capitalize;
      }

      &:hover {
        color: $primary;
        opacity: 0.5;

        @include transition(all 0.25s ease-in);

        a {
          @include responsive(minpc) {
            color: $primary;
            opacity: 0.25;

            @include transition(all 0.25s ease-in);
          }
        }
      }
    }

    & > span {
      font-weight: 400;
      opacity: 0.35;
      font-size: 11px;

      @include responsive(tablet) {
        margin: 5px 0;
        display: inline-block;
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}

/* Product page - floating cart */
.product-page.floating-cart {
  display: none;
}
