/* Common: Classes
--------------------------------------------------------------------------------------------------------------------- */

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  @include responsive(pc) {
    text-rendering: auto;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

.clearfix {
  clear: both;
  float: none;
  margin: 0;
  padding: 0;
  height: 0;
}

iframe {
  border: 0 solid transparent;
  width: 100%;
  height: 100%;
}

select,
textarea,
input[type="text"] {
  @include apearance(none);

  letter-spacing: 0;
  border-radius: 5px;
  border: none;
  box-shadow: none;
}

/* GENERALES */

body {
  background: $white;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-text;
  font-size: 14px;
  margin: 0;
  width: 100%;
  overflow-x: hidden;

  @include responsive(pc) {
    font-size: 16px;
  }

  & > main {
    & > section:first-of-type {
      margin-top: 115px !important;

      @include responsive(pc) {
        margin-top: 150px !important;
      }
    }
  }
}

main {
  width: 100%;
  min-height: 50vh;
}

.page-product {
  main {
    & > section:first-of-type {
      margin-top: 0;
    }
  }
}

div {
  margin: 0;
  padding: 0;
}

p {
  line-height: 20px;
}

/* ENCABEZADOS */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  text-align: left;
}

h1,
.h1 {
  color: $black;
  font-family: $font-title;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 35px;
}

h2,
.h2 {
  color: $mid-grey;
  font-family: $font-title;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 25px;
}

h3,
.h3 {
  color: $mid-grey;
  font-family: $font-title;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  font-size: 25px;
}

h4,
.h4,
h5,
.h5 {
  font-family: $font-title;
}

button {
  background-color: $primary;
  border: 1px solid transparent;
  color: $white;
  padding: 12px 30px;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 0 !important;
  font-family: $font-title;
  text-transform: uppercase;

  &:hover {
    @include responsive(minpc) {
      background-color: $background-transparent;
      border: 1px solid $primary;
      color: $primary;
      cursor: pointer;
    }
  }
}

a,
button {
  text-decoration: none;
  color: $dark-grey;
  font-size: inherit;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal;
  font-family: $font-title;

  @include transition(all 0.45s ease-in);

  &:hover {
    @include responsive(minpc) {
      color: $mid-grey;
      cursor: pointer;
    }

    i {
      @include responsive(minpc) {
        color: $mid-grey;
      }
    }
  }

  &.btn {
    &--primary {
      background-color: $primary;
      border: 1px solid transparent;
      color: $white;
      padding: 12px 26px;
      text-align: center;
      letter-spacing: 2px;
      font-size: 13px;
      overflow: hidden;
      position: relative;
      display: inline-block;
      font-weight: 500;

      @include transition (all 0.45s ease-in);

      &-swapped {
        border: 1px solid $primary;
        background: $white;
        color: $primary;
        padding: 12px 30px;
        display: inline-block;
        letter-spacing: 2px;
        font-weight: 500;

        @include transition (all 0.45s ease-in);

        &:hover {
          @include responsive(minpc) {
            background-color: $primary;
            border: 1px solid $primary;
            color: $white;

            @include transition (all 0.45s ease-in);

            i {
              color: white;
            }
          }
        }
      }

      &:hover {
        @include responsive(minpc) {
          background-color: $white;
          border: 1px solid $primary;
          color: $primary;

          @include transition (all 0.45s ease-in);
        }
      }
    }

    &--underline {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $dark-grey;
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s;
      }

      &:hover {
        &::after {
          @include responsive(minpc) {
            background-color: $primary;
            transform-origin: 0% 50%;
            transform: scale3d(1, 1, 1);
          }
        }
      }
    }
  }
}

/* LISTAS */

ol {
  margin: 0;
  list-style: none;
  counter-reset: li;

  li {
    padding: 0 0 10px 0;
    counter-increment: li;
    font-weight: 500;

    &::before {
      content: counter(li);
      display: inline-block;
      width: 20px;
      margin-right: 15px;
      margin-left: -40px;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 0 0 10px 0;
    text-decoration: none;
    font-weight: 500;
  }
}

/* FORMS */

input {
  padding: 12px 30px;
  font-size: 14px;
  border: 1px solid transparent;
  letter-spacing: 2px;
}

:active,
:focus {
  border: none;
  outline: none;
  text-decoration: none;
}

.form-block {
  max-width: 300px;
}

input[type="submit"] {
  border-radius: 0;
  width: auto;
  background-color: $primary;
  border: 1px solid transparent;
  color: $white;
  padding: 12px 30px;
  text-transform: uppercase;
  appearance: none;

  @include transition(all 0.2s ease 0s);

  &:hover {
    @include responsive(minpc) {
      background-color: $background-transparent;
      border: 1px solid $primary;
      color: $primary;
      cursor: pointer;
    }
  }
}

input[type="search"] {
  border: none;
  border-radius: 0;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
}

//Inputs
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white !important;
  border: 1px solid $primary !important;
  width: 15px;
  height: 15px;
  margin-right: 15px;

  @include transition (all 0.2s ease-in);

  &:hover {
    @include responsive(minpc) {
      background-color: $secondary !important;
      cursor: pointer;
    }
  }
}

//Input radio checkout
body#checkout input[type="radio"] {
  &:checked {
    background-color: $primary-hover !important;
    border: 1px solid $primary-hover !important;

    &:hover {
      @include responsive(minpc) {
        background-color: $primary-hover !important;
      }
    }
  }
}

//Inputs producto
.custom-radio {
  border: 1px solid $secondary;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  position: relative;

  input[type="radio"] {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
    border: none;
    margin-right: 0;

    &:checked {
      background-color: $secondary !important;
      border: none;
    }

    &:hover {
      @include responsive(minpc) {
        background-color: $secondary;
      }
    }

    & + label {
      &:hover {
        @include responsive (minpc) {
          cursor: pointer;
        }
      }
    }
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"] {
  letter-spacing: 0;
  border: none;
  border-radius: 0;
  width: 100%;

  @include transition(border-bottom 0.2s ease 0s);

  &:focus {
    color: inherit;
  }

  &:hover {
    @include transition(background-color 0.2s ease 0s);
  }

  &.disabled {
    &:hover {
      color: inherit;
    }

    opacity: 0.5;
  }
}

input[type="date"],
input[type="number"],
input[type="time"] {
  width: 100%;

  @include transition(all 0.2s ease 0s);
}

input[type="number"] {
  max-width: 60px;
  margin-left: 10px;
}

textarea[rows] {
  height: auto;
}

textarea {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 0;
  font-size: 14px;
}

/* TABLES */
table {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding-top: 10px;
  }

  .table_center {
    text-align: right;
  }

  th {
    text-align: left;
  }
}

.product {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  padding: 10px;

  & > div {
    width: 100%;
  }

  a {
    width: 100%;
    font-family: $font-title;
  }

  @include responsive(tablet) {
    padding: 0 0 10px;
  }

  @include transition(all 0.45s ease-in);

  &--label {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 60px;
    height: 26px;
    background-color: $mid-grey;
    color: $white;
    font-size: 14px;
    text-align: center;
    padding: 3px 0;

    @include responsive(tablet) {
      right: 40px;
      top: 0;
    }

    @include responsive(movil) {
      right: 0;
    }
  }

  &--image {
    width: 100%;
    max-height: 285px;
    object-fit: contain;
    object-position: center;
    border: 1px solid $secondary;
    padding: 25px;
    background-color: $white;

    @include transition(all 0.45s ease-in);

    @include responsive(milseiscientos) {
      max-height: 205px;
    }

    @include responsive(pantalla) {
      max-height: 250px;
    }

    @include responsive(pc) {
      max-height: 225px;
    }

    @include responsive(tablet) {
      max-height: 325px;
    }

    @include responsive(movil) {
      max-height: 200px;
    }
  }

  &--details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .title {
      font-size: 14px;
      text-transform: initial;
      font-family: $font-text;
      width: 100%;
      padding: 12.5px 0;
      height: 70px;

      @include responsive(minimovil) {
        font-size: 12.5px;
      }
    }

    .wishlist {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 15px;
      margin-top: 10px;

      i {
        padding: 4px;
        border-radius: 100px;
      }

      &:hover {
        i {
          @include responsive(minpc) {
            background-color: $primary;
            color: $white;

            @include transition(all 0.45s ease-in);
          }
        }
      }
    }
  }

  .btn {
    appearance: none;
    font-family: $font-title;

    &--primary {
      display: flex;
      width: calc(100% + 20px);
      margin: 10px -10px -10px;
      opacity: 0;
      justify-content: center;
      letter-spacing: 2px;
      align-items: center;
      transform: translateY(50px);

      @include responsive(pc) {
        margin: 10px 0 -10px;
        opacity: 1;
        transform: translateY(0);
        font-size: 13px;
      }

      @include responsive(tablet) {
        width: 100%;
      }

      @include responsive(movil) {
        padding: 10px;
      }

      @include responsive(minimovil) {
        font-size: 12px;
        padding: 10px 5px;
      }

      @include transition(all 0.45s ease-in);

      &:hover {
        @include responsive(minpc) {
          color: $primary;
          border: 1px solid $primary;

          @include transition(all 0.45s ease-in);
        }
      }
    }

    &-primary {
      display: flex;
      width: calc(100% + 20px);
      margin: 10px -10px -10px;
      opacity: 0;
      justify-content: center;
      align-items: center;
      transform: translateY(50px);

      @include responsive(pc) {
        margin: 10px 0 -10px;
        opacity: 1;
        transform: translateY(0);
        font-size: 13px;
      }

      @include responsive(tablet) {
        width: 100%;
      }

      @include responsive(movil) {
        padding: 10px;
      }

      @include responsive(minimovil) {
        font-size: 12px;
        padding: 10px 5px;
      }

      @include transition(all 0.45s ease-in);

      &:hover {
        @include responsive(minpc) {
          background-color: $primary-hover;
          color: $white;
          border: 1px solid $primary-hover;
        }
      }
    }
  }

  &:hover {
    @include responsive(minpc) {
      background-color: $secondary;
    }

    .product--image {
      @include responsive(minpc) {
        border: 1px solid transparent;
      }
    }

    .btn {
      &--primary {
        @include responsive(minpc) {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}

.price {
  font-size: 20px;
  color: $black;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;

  @include responsive(tablet) {
    margin-top: 0;
  }

  @include responsive(minimovil) {
    font-size: 18px;
  }

  &--strike {
    text-decoration: line-through;
    color: $light-grey;
    margin-left: 5px;

    @include responsive(pc) {
      margin-left: 10px;
    }
  }

  &--normal {
    color: $black;
  }

  &.product--discount,
  &--discount {
    color: #fa7272;
  }
}

.rating {
  & > div {
    padding: 30px;
    border: 1px solid $secondary;
    background-color: $white;

    &--description {
      line-height: 20px;
      font-weight: 600;
      margin-top: 0;
    }

    &--stars {
      width: 100%;
      display: flex;
      align-items: center;

      .text {
        color: $light-grey;
        font-weight: 700;
        margin: 0 60px 0 0;
      }
    }

    .icon-star--empty {
      color: $light-grey;
    }
  }

  &--info {
    color: $primary;
    font-weight: 600;
  }
}

.post {
  width: 585px;
  display: block;
  background-color: $secondary;

  @include responsive(pc) {
    width: calc(100% - 50px);
    margin: 25px;
  }

  &--image {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;

    @include transition(all 0.35s ease-in);

    .image {
      width: 100%;
      object-fit: cover;
    }

    &::before {
      content: "+";
      color: #fff;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0);
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: 400;
      display: flex;
      opacity: 0;

      @include transition(all 0.35s ease-in);
    }

    &:hover {
      &::before {
        @include responsive(minpc) {
          opacity: 1;

          @include transition(all 0.35s ease-in);
        }
      }
    }
  }

  &--info {
    padding: 30px;
    text-transform: uppercase;
    background-color: $secondary;

    @include responsive(tablet) {
      padding: 18.75px;
    }

    .category {
      color: $mid-grey;
      font-weight: 700;
      margin-top: 0;
      font-size: 12px;

      @include responsive(pantalla) {
        height: 40px;
        min-height: 40px;
        font-size: 13px;
      }
    }

    .title {
      color: $primary;
      font-family: $font-title;
      text-transform: initial;
      margin-bottom: 0;
      font-size: 20px;
      height: 95px;
      min-height: 95px;
      font-weight: 500;

      @include responsive(pantalla) {
        font-size: 18px;
        height: 70px;
        min-height: 70px;
      }
    }

    .text {
      @include responsive(pantalla) {
        font-size: 14px;
      }
    }
  }

  &:hover {
    @include responsive(minpc) {
      cursor: pointer;
    }
  }
}

.tns {
  &-nav {
    button {
      width: 7.5px;
      height: 7.5px;
      background-color: transparent;
      border: 1px solid $black;
      border-radius: 100%;
      padding: 0;
      margin-bottom: 5px;

      &.tns-nav-active {
        background-color: $black;
      }
    }
  }
}

.styleguide-section,
.home.styleguide-section,
.category.styleguide-section {
  margin: 25px 50px;
  padding: 0;
  width: calc(100% - 100px);

  & > a {
    display: inline-block;
    margin: 25px;
  }

  & > input {
    margin: 25px;
  }
}

.styleguide-element {
  margin: 50px 50px 0;
}

.home__ratings.styleguide-section {
  .home__ratings-slider {
    display: flex;
    justify-content: space-between;

    .home__ratings-slider-rating.rating {
      width: calc(33.33% - 20px);
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.home__news.styleguide-section {
  width: calc(100% - 100px);

  .home__news-slider {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > div {
      width: calc(50% - 25px);
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/* Modal Youtube */
.modal-video {
  display: none;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000000000000;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__video {
    width: 1080px;
    min-height: 610px;
    height: 610px;

    @include responsive(portatil) {
      width: 850px;
      min-height: 500px;
      height: 500px;
    }

    @include responsive(pantallamin) {
      width: 700px;
      min-height: 400px;
      height: 400px;
    }

    @include responsive(tablet) {
      width: 500px;
      min-height: 300px;
      height: 300px;
    }

    @include responsive(bigmovil) {
      width: 400px;
      min-height: 200px;
      height: 200px;
    }

    @include responsive(movil) {
      width: 300px;
      min-height: 150px;
      height: 150px;
    }

    .modal-img__container {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  &__close {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 25px;
    right: 25px;

    i {
      color: $white;
      font-size: 30px;

      &:hover {
        @include responsive(minpc) {
          cursor: pointer;
          color: $primary;
        }
      }
    }
  }

  &.active {
    display: flex;
  }
}

.wishlist-alert {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 160px;
  right: 40px;
  background-color: $secondary;
  color: $primary;
  font-weight: 600;
  transform: translateX(300px);
  padding: 6px 12.5px;
  transition: all 0.5s ease;

  @include responsive(pc) {
    right: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  &--show {
    transform: translateX(0);
  }
}

.wishlist-alert-login {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 160px;
  right: 0;
  background-color: $secondary;
  color: $primary;
  font-weight: 600;
  transform: translateX(300px);
  padding: 6px 12.5px;
  transition: all 0.5s ease;

  p {
    margin: 0;
    padding: 0;
  }

  &--show {
    transform: translateX(0);
  }
}

.blockcart-modal {
  position: fixed;
  right: 40px;
  top: 160px;
  transition: all 0.5s ease;
  transform: translateX(300px);

  .modal-body {
    background-color: $secondary;
    color: $primary;
    font-weight: 600;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      margin: 0;
    }

    a {
      padding: 6px 0;
      font-size: 12px;
      margin-top: 6px;
      width: auto;
      display: inline-block;
    }
  }

  &.show {
    transform: translateX(0);
  }
}

/* Animation pulse heart wishlist */
@keyframes pulse {
  10% {
    transform: scale(1.2);
    transition: all 0.2s ease;
  }

  50% {
    transform: scale(1);
    transition: all 0.2s ease;
  }

  100% {
    transform: scale(1.2);
    transition: all 0.2s ease;
  }
}

#js-replace-me-autoplay {
  height: 100%;
  width: 100%;
}

//invalid feedback
.invalid-feedback {
  background-color: #f4d2d4;
  color: #ab000e;
  border-radius: 3px;
  padding: 6px 12px;
  margin: 12px 0 0;
  font-weight: 600;
  display: inline-block;
  font-size: 11px;

  &:empty {
    display: none;
  }
}

// Cookies
.page-wrapper--module-lgcookieslaw-disallow,
.page-wrapper--module-jproductcomments-customercomments {
  max-width: 1080px;
  margin: 0 auto;
  padding: 100px;

  @include responsive(pc) {
    width: 100%;
    padding: 20px;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
    clear: none;
  }

  h2 {
    text-transform: initial;

    @include responsive(pc) {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

//jsproductcomments
.custom-comments {
  width: calc(100% + 20px);
  margin: 0 -10px;
  display: block;

  tr {
    width: calc(100% - 20px);
    margin: 10px;
    display: block;
    border: 1px solid #dedede;

    td {
      display: block;
      width: 100%;
      padding: 20px;

      .col-lg-7 {
        margin-bottom: 10px;
      }

      .col-lg-4 {
        margin-right: 20px;
      }

      a {
        padding: 0;
        display: inline-block;
      }
    }
  }

  &__h3 {
    text-transform: inherit;
    font-size: 14px;
  }

  .comment_form {
    form {
      margin: 20px 0 0;

      .new_comment_form_content {
        .criterions {
          li {
            display: flex;
            align-items: center;

            .star_content {
              margin-bottom: 0;
              margin-left: 15px;
            }
          }
        }

        .form-group {
          margin-top: 20px;
        }

        .form-control {
          background-color: $secondary;
          margin: 10px 0 20px;
          padding: 10px;
          width: 100%;
        }

        textarea {
          resize: vertical;
        }

        input[type="file"] {
          background-color: transparent;
          padding: 0;
          letter-spacing: 0;
          font-size: 11px;
        }

        .fl.required {
          display: none;
        }

        button[type="submit"] {
          span {
            color: $white;
          }

          &:hover {
            span {
              @include responsive(minpc) {
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}

.custom-star {
  display: inline-block;
  position: relative;

  .star {
    background: transparent;
    border: transparent;

    i {
      border: 1px solid black;
      color: transparent;
    }

    &:checked {
      background: transparent;
      border: transparent;

      & + i {
        color: black;
      }

      &:hover {
        background: transparent;
      }
    }

    &:hover {
      background: transparent;
    }
  }

  i.icon-star {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    color: rgba(0, 0, 0, 0.25);
  }
}

.page-footer {
  margin-top: 20px;
}

.loading {
  border: none !important;
}
