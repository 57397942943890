/* Layouts: Page search
--------------------------------------------------------------------------------------------------------------------- */
.page-search {
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .breadcrumbs-list {
    display: none;
  }

  .content__main {
    padding: 50px;
    min-height: calc(100vh - 450px);
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive(pc) {
      padding: 50px 25px;
    }

    @include responsive(movil) {
      padding: 25px 12.5px;
    }

    h1 {
      font-size: 30px;

      @include responsive(movil) {
        font-size: 25px;
      }
    }

    .page-content.page-not-found {
      width: 100%;
    }

    .page-not-found {
      .search-widget {
        form {
          .input-group {
            display: flex;
            align-items: stretch;
            max-width: 500px;

            input {
              background-color: $secondary;
            }

            button {
              i {
                display: none;
              }

              span {
                color: $white;

                @include transition(all 0.2s ease-in);
              }

              &:hover {
                span {
                  @include responsive(minpc) {
                    color: $primary;
                  }
                }
              }
            }
          }
        }
      }
    }

    #products {
      & > div {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 25px 0;

        @include responsive(tablet) {
          margin: 12.5px 0;
        }

        .products-selection {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include responsive(tablet) {
            flex-wrap: wrap;
          }

          & > div {
            width: auto;
            margin-right: 12.5px;

            &:first-child {
              margin-left: 12.5px;

              @include responsive(pantalla) {
                display: none;
              }
            }

            &:nth-of-type(2) {
              @include responsive(pantalla) {
                width: 50%;
                margin: 12.5px 0;
              }

              @include responsive(movil) {
                width: 100%;
                text-align: left;
              }

              .form-inline {
                .form-group {
                  .custom-select {
                    font-weight: 600;

                    &:hover {
                      @include responsive(minpc) {
                        cursor: pointer;
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }

            &:last-child {
              text-align: right;
              width: 60%;

              @include responsive(pantalla) {
                width: 50%;
                text-align: right;
                margin: 12.5px 0;
              }

              @include responsive(movil) {
                text-align: left;
                width: 100%;
                margin: 0 0 12.5px;
              }
            }
          }
        }

        .product {
          width: 16.66%;
          margin-bottom: 12.5px;

          @include responsive(pantalla) {
            width: calc(25% - 12.5px);
            margin: 6.25px;
          }

          @include responsive(pc) {
            width: calc(33.33% - 12.5px);
            margin: 6.25px;
          }

          @include responsive(movil) {
            width: calc(50% - 12.5px);
            margin: 6.25px;
          }

          &:nth-of-type(2n) {
            @include responsive(movil) {
              margin-right: 0;
            }
          }

          .btn {
            &--primary {
              @include responsive(pantallaca) {
                margin: 10px 0 0;
                opacity: 1;
                transform: translateY(0);
                padding: 10px;
              }
            }
          }
        }
      }

      div#js-product-list-bottom {
        margin-top: 25px;
      }
    }
  }
}
