.category {
  &__description {
    display: flex;
    align-items: stretch;
    margin: 0 50px;
    width: calc(100% - 100px);
    overflow: hidden;

    @include responsive(pc) {
      margin: 0;
      width: 100%;
      position: relative;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      max-height: inherit;
    }

    @include responsive(pantalla) {
      margin: 0;
      width: 100%;
    }

    &-container {
      width: 50%;
      background-color: $pink;
      padding: 25px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      z-index: 0;
      height: auto;

      @include responsive(pc) {
        width: 100%;
        order: 2;
      }

      @include responsive(movil) {
        padding: 25px 12.5px;
      }
    }

    &-breadcrumb {
      width: 100%;
      z-index: 8;
      min-height: 20px;

      @include responsive(pc) {
        position: absolute;
        top: 25px;
        left: 25px;
      }

      @include responsive(movil) {
        top: 12.5px;
        left: 12.5px;
      }

      a {
        font-size: 10px;
        position: relative;
        z-index: 8;

        &::after {
          content: "|";
          padding-left: 5px;
          margin-right: 5px;
        }

        &:last-child {
          &::after {
            content: "";
          }
        }

        &:hover {
          @include responsive(minpc) {
            opacity: 0.5;
          }
        }
      }
    }

    &-content {
      width: 80%;
      z-index: 1;

      @include responsive(pantalla) {
        width: 100%;
      }

      .title,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 30px;
        margin: 25px 0;

        @include responsive(pantalla) {
          margin: 40px 0 25px;
        }
      }

      .text,
      p {
        margin: 25px 0;
        font-size: 16px;
        line-height: 22px;

        @include responsive(pantallaca) {
          font-size: 14px;
          line-height: 20px;
        }

        &:empty {
          display: none;
        }
      }

      .btn,
      a {
        margin: 25px 0 50px;
        display: inline-block;

        @include responsive(movil) {
          margin: 25px 0 0;
        }
      }

      .decorative-category {
        position: absolute;
        top: 25px;
        right: -120px;
        width: 250px;
        min-height: 311px;
        z-index: -1;
      }
    }

    &-img {
      width: 50%;
      height: auto;
      position: relative;
      overflow: hidden;

      @include responsive(pc) {
        width: 100%;
        order: 1;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        height: 0;
        overflow: hidden;
        padding-bottom: 49.25%;
        padding-top: 0;
        position: relative;
      }

      .iconplay {
        position: absolute;
        width: 60px;
        height: 60px;
        left: calc(50% - 30px);
        top: calc(50% - 30px);
        font-size: 40px;
        color: $white;
        background: $white;
        border-radius: 100%;
        padding: 5px;
        z-index: 3;
        transition: all 0.5s ease-in-out;

        .stroke-solid {
          stroke-dashoffset: 0;
          stroke-dasharray: 300;
          stroke-width: 4px;
          transition: stroke-dashoffset 1s ease, opacity 1s ease;
        }

        .icon {
          transform: scale(0.8);
          transform-origin: 50% 50%;
          transition: transform 200ms ease-out;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.75);
          transition: all 0.5s ease-in-out;
        }

        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }
      }

      .play {
        width: 100%;
        height: 100%;
        overflow: hidden;

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.15);
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          visibility: hidden;
          opacity: 0;
          z-index: 2;
          transition: all 0.5s ease-in-out;
        }

        &:hover {
          &::before {
            visibility: visible;
            opacity: 1;
            transition: all 0.5s ease-in-out;
          }

          .iconplay {
            color: $primary;

            .stroke-solid {
              opacity: 1;
              stroke-dashoffset: 300;
            }

            .icon {
              transform: scale(0.9);
            }
          }

          .visual-img {
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
          }
        }
      }

      .visual {
        &-img {
          object-fit: cover;
          width: 100%;
          height: auto;
          min-height: 100%;
          min-width: 100%;
          display: flex;
          z-index: 1;
          // transition: all 0.5s ease-in-out;
        }

        &-video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      &--playing {
        .visual-img {
          display: none;
        }

        .visual-video {
          display: block;
        }

        > a {
          display: none;
        }

        &::before {
          background-image: transparent;
        }
      }

      &--active {
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }

        // &::after {
        //   content: "";
        //   float: left;
        //   padding-top: 56.5%;
        //   width: 100%;

        //   // @include responsive(movil) {
        //   //   float: none;
        //   // }
        // }

        #js-replace-me-autoplay {
          position: absolute;
        }
      }

      @include responsive(movil) {
        iframe {
          // width: 100%;
          object-fit: cover;
          // height: auto;
          min-width: 100%;
          min-height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0;
          // left: 0;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}
