/* Layouts: Header
--------------------------------------------------------------------------------------------------------------------- */
.header {
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 32.5px;
  z-index: 999;

  @include responsive(pc) {
    padding-bottom: 46.5px;
  }

  &__topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $secondary;
    padding: 0 50px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 8;
    left: 0;

    @include transition (all 0.25s ease-in);

    @include responsive(pantalla) {
      padding: 0 25px 2.5px;
      justify-content: center;
    }

    @include responsive(movil) {
      padding: 0 2.5px 2.5px;
      justify-content: center;
    }

    &-shipping {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include responsive(pantalla) {
        justify-content: center;
        width: 100%;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 45px;

        @include responsive(pantalla) {
          margin-right: 0;
          width: 100%;
          justify-content: center;
          height: 30px;
        }

        &:last-child {
          margin-right: 0;
        }

        &:nth-of-type(2) {
          @include responsive(pantalla) {
            display: none;
          }
        }

        &:nth-of-type(3) {
          @include responsive(pantalla) {
            display: none;
          }
        }

        i {
          margin: 0 5px 0 10px;
          font-size: 18px;
          min-width: 19px;

          @include responsive(pc) {
            font-size: 15px;
            min-width: 16px;
          }

          &:first-child {
            margin-left: 0;
          }

          &.icon-truck {
            font-size: 20px;
          }
        }

        .text {
          margin: 5px;
          font-size: 11px;
          font-weight: 500;
          height: 20px;

          .none-mobile {
            @include responsive(pantalla) {
              display: none;
            }
          }
        }
      }
    }

    &-phone {
      @include responsive(pantalla) {
        display: none;
      }
    }

    &--hide {
      @include responsive(portatil) {
        display: none;
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px 50px;

    @include responsive(portatil) {
      margin: 12.5px 25px;
      max-height: 40px;
      min-height: 40px;
      height: 40px;
    }

    @include responsive(movil) {
      margin: 12.5px;
    }

    &-corporate {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 60%;
      min-height: 40px;
      z-index: 999999999;

      @include responsive(portatil) {
        width: 80%;
        height: 40px;
        max-height: 40px;
      }

      @include responsive(movil) {
        width: 50%;
      }

      .logo {
        width: 175px;
        margin-right: 50px;

        @include responsive(pc) {
          width: 100%;
          max-width: 175px;
          height: 30px;
          margin-right: 0;
          z-index: 10;
        }
      }

      .container-search {
        width: 100%;
        position: relative;
        display: flex;

        @include responsive(pc) {
          position: absolute;
          bottom: 12.5px;
          left: 25px;
          width: calc(100% - 50px);
        }

        @include responsive(movil) {
          left: 12.5px;
          width: calc(100% - 25px);
        }

        input[type="search"] {
          border: 1px solid $black;
          border-radius: 50px;
          padding: 8px 16px;
          letter-spacing: 0;

          @include responsive(pantalla) {
            width: 100%;
          }

          &::placeholder {
            letter-spacing: 0;
            color: $light-grey;
          }

          &:focus {
            border: 1px solid $primary;
          }
        }

        .search-input {
          position: absolute;
          right: 0;
          top: 0;

          & > div {
            position: relative;

            input {
              position: absolute;
              right: 0;
              top: 0;
              height: 34px;
              display: block;
              z-index: 9;
              border-radius: 50px;
              border: 1px solid transparent;
              background-color: transparent !important;
            }

            i {
              position: absolute;
              right: 12.5px;
              top: 1px;
              color: #000;
              font-size: 16px;
              z-index: 8;
              height: 32px;
              width: 34px;
              background-color: white;
              border-radius: 50px;
              border: 1px solid transparent;
              display: flex;
              align-items: center;
              justify-content: center;

              @include responsive(movil) {
                background-color: transparent;
              }
            }
          }
        }

        &--hide {
          @include responsive(portatil) {
            display: none;
          }
        }
      }

      .menu-mobile {
        display: none;
        width: 40px;
        max-width: 24px;
        height: auto;
        margin-right: 12.5px;
        border: none;
        background: none;
        padding: 0;
        z-index: 10;

        @include responsive(pc) {
          width: 24px;
        }

        &:hover {
          @include responsive(pantalla) {
            cursor: pointer;
          }
        }

        @include responsive(portatil) {
          display: block;
        }

        span {
          display: block;
          width: 100%;
          height: 2px;
          background: $black;
          position: relative;
          margin-top: 5px;

          &:nth-child(1) {
            margin-top: 0;
          }
        }

        &--opened {
          span {
            &:nth-child(1) {
              animation: ease 0.7s top forwards;
            }

            &:nth-child(2) {
              animation: ease 0.7s scaled forwards;
            }

            &:nth-child(3) {
              animation: ease 0.7s bottom forwards;
            }
          }
        }
      }

      .custom-search {
        top: inherit !important;
        border-radius: 100px;
        padding: 0 16px;
        min-height: 30px;

        @include responsive(pantallaca) {
          width: 75% !important;
        }

        @include responsive(pc) {
          width: calc(100% - 50px) !important;
          top: inherit !important;
          position: absolute !important;
          bottom: 9.25px;
          left: 25px;
          height: 31.2px;
          min-height: 31.2px;
          max-height: 31.2px;
        }

        input {
          font-size: 12px;
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 40%;
      position: relative;

      @include responsive(pantalla) {
        width: 50%;
        min-width: 175px;
      }

      .header-wishlist {
        @include responsive(pantalla) {
          display: none;
        }
      }

      .btn {
        margin: 10px;
        padding: 5px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
        min-width: 30px;

        @include responsive(tablet) {
          margin: 5px;
        }

        @include responsive(movil) {
          margin: 2.5px;
        }

        &--primary {
          padding: 8px 16px;
          font-size: 12px;
          font-family: $font-title;
          font-weight: 500;
          letter-spacing: 2px;
          white-space: nowrap;

          @include responsive(pantalla) {
            display: none;
          }

          &:hover {
            @include responsive(minpc) {
              background-color: $primary-hover;
              color: $white;
              border: 1px solid $primary-hover;

              @include transition(all 0.45s ease-in);
            }
          }
        }

        i {
          font-size: 20px;
          min-width: 20px;
        }

        &.bag {
          position: relative;

          .quantity {
            position: absolute;
            background-color: red;
            color: $white;
            border-radius: 100%;
            bottom: -2.5px;
            right: -2.5px;
            display: block;
            width: 12.5px;
            height: 12.5px;
            font-size: 10px;
            line-height: 4px;
            text-align: center;
            padding-top: 4.5px;
          }
        }

        &.languages {
          margin: 0;
          padding: 10px;
          padding-right: 0;
          min-width: 55px;
          min-height: 40px;

          @include responsive(pantalla) {
            margin-right: 0;
          }
        }

        &:hover {
          @include responsive(mintablet) {
            background-color: $primary;

            @include transition (all 0.3s ease-in);

            i {
              color: $white;

              @include transition (all 0.3s ease-in);
            }
          }
        }

        &.btn--primary {
          border-radius: 0;

          &:hover {
            background-color: $white;
            color: $black;
          }
        }

        &:last-child {
          &:hover {
            background-color: $white;
            color: $primary;

            i {
              color: $primary;
            }
          }
        }
      }

      .blockcart.cart-preview {
        min-height: 40px;
        min-width: 40px;
      }

      &--hide {
        @include responsive(portatil) {
          display: none;
        }
      }

      .languages {
        position: relative;
        z-index: 1000000000000;

        &__selected {
          font-size: 16px;
          font-weight: 400;

          i {
            display: inline-block;
            transform: rotate(90deg);
            font-size: 12px;

            &.icon-rotation {
              &-active {
                transform: rotate(270deg);
              }
            }
          }
        }

        &__submenu {
          display: none;
          position: absolute;
          right: 0;
          top: 35px;
          background-color: $white;
          width: 57px;
          padding: 6.25px 12.5px;
          border: 1px solid $secondary;
          z-index: 999999;

          &-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            a {
              font-weight: 400;
            }

            &:last-child {
              padding: 0;
            }
          }
        }

        &-visible {
          .languages__submenu {
            display: block;
          }
        }
      }
    }
  }

  &__menu {
    @include responsive(minpc) {
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    @include responsive(portatil) {
      padding: 0 25px;
      display: none;
    }

    &-principal {
      @media (min-width: 1290.1px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-item {
        text-transform: initial;

        &.header__menu-principal-item {
          background-color: transparent;
        }

        @include responsive(minpc) {
          padding-right: 15px;
          border-bottom: 1px solid transparent;
          padding-bottom: 7.5px;
        }

        @include responsive(milcuatrocientos) {
          padding-right: 10px;
        }

        @include responsive(portatil) {
          font-size: 11px;
        }

        @include responsive(pantalla) {
          font-size: 12px;
          padding-right: 15px;
        }

        @include responsive(pc) {
          font-size: 14px;
        }

        &:first-child {
          @include responsive(minpc) {
            text-transform: uppercase;
            color: $primary;
          }

          & > span {
            text-transform: uppercase;
            color: $primary;
            font-weight: 800;
            letter-spacing: 1px;
            font-size: 11px;

            @include responsive(pantallaca) {
              font-size: 11px;
            }

            @include responsive(portatil) {
              font-size: 10px;
            }

            @include responsive(pantalla) {
              font-size: 12px;
            }
          }
        }

        &:nth-child(2) {
          text-transform: initial;

          span {
            &::after {
              content: "|";
              margin-left: 10px;

              @include responsive(portatil) {
                display: none;
              }
            }
          }
        }

        &.header__corporate-item {
          &:nth-child(2) {
            span {
              &::after {
                content: "";
                margin-left: 0;
              }
            }
          }

          a {
            @include responsive(portatil) {
              padding: 3.125px 0;
              font-size: 12px;
              text-transform: capitalize;
            }
          }

          &:last-child {
            a {
              @include responsive(portatil) {
                font-size: 12px;
              }
            }
          }
        }

        &-borderbottom {
          &::after {
            @include responsive (portatil) {
              content: "";
              position: absolute;
              left: 25px;
              bottom: 0;
              width: calc(100% - 50px);
              height: 1px;
              background-color: $primary;
            }

            @include responsive (movil) {
              left: 0;
              width: 100%;
            }
          }
        }

        .separator {
          @include responsive(minpc) {
            margin-left: 12.5px;
          }

          @include responsive(pantalla) {
            display: none;
          }
        }

        .submenu {
          @include responsive(minportatil) {
            display: none;
            columns: 4;
            position: absolute;
            top: 20px;
            background-color: $white;
            width: 100%;
            height: auto;
            min-height: 350px;
            left: 0;
            padding: 50px;
            box-shadow: inset 0 4px 10px -4px rgba(0, 0, 0, 0.05);
            padding-right: calc(20% + 50px);
            z-index: 9999999999999;
          }

          @include responsive(pantalla) {
            top: 0;
          }

          &__img {
            &-mobile {
              display: none;

              @include responsive(portatil) {
                display: block;
              }

              &-header {
                display: none;

                @include responsive(portatil) {
                  display: block;
                  width: 100%;
                  max-height: 180px;
                  object-fit: cover;
                }
              }
            }

            &-desktop {
              @include responsive(minportatil) {
                display: block;
                position: absolute;
                right: 50px;
                width: 20%;
                top: 50px;
                bottom: 50px;
                overflow: hidden;
              }

              @include responsive(pantalla) {
                display: none;
              }

              img {
                object-fit: cover;
                height: auto;
                width: 100%;
                min-height: 300px;
              }
            }
          }

          &__item {
            padding-right: 15px;
            color: $mid-grey;

            @include transition(all 0.25s ease-in);

            a {
              text-transform: initial;
              width: 100%;
              display: inline-block;
              align-items: center;
              justify-content: space-between;

              @include transition(all 0.25s ease-in);

              i {
                display: inline-block;
                margin-left: 6.25px;
                color: $mid-grey;
                font-size: 8px;
              }
            }

            span {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              @include transition(all 0.25s ease-in);

              i {
                display: inline-block;
                margin-left: 6.25px;
                color: $mid-grey;
                font-size: 8px;
              }
            }

            &-mobileonly {
              display: none !important;
              border-bottom: 1px solid $secondary;

              @include responsive(portatil) {
                display: block !important;
              }

              a,
              span {
                justify-content: flex-start;

                i {
                  display: inline-block;
                  margin-right: 6.25px;
                  margin-left: 0;
                  color: $mid-grey;
                  font-size: 8px;
                  transform: rotate(180deg);
                }
              }
            }

            &:hover {
              @include responsive(minportatil) {
                cursor: pointer;

                @include transition(all 0.25s ease-in);
              }

              a,
              span {
                @include responsive(minportatil) {
                  opacity: 0.5;

                  @include transition(all 0.25s ease-in);
                }
              }
            }
          }

          &.submenu-small {
            @include responsive(minportatil) {
              width: 190px;
              columns: 1;
              padding: 25px;
              left: initial;
              border: 1px solid $secondary;
            }

            .submenu__item {
              @include responsive(minportatil) {
                text-align: left;
              }
            }
          }

          &.submenu-custom {
            @include responsive(minportatil) {
              columns: 1;
              padding: 50px 50px 175px;
              box-shadow: 2.5px 2.5px 20px rgba(0, 0, 0, 0.05);
              -webkit-box-shadow: 2.5px 2.5px 20px rgba(0, 0, 0, 0.05);
              -moz-box-shadow: 2.5px 2.5px 20px rgba(0, 0, 0, 0.05);
            }

            .submenu__item {
              @include responsive(minportatil) {
                padding-right: 25px;
                width: 350px;
              }

              span {
                @include responsive(minportatil) {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  color: $primary;
                }

                i {
                  @include responsive(minportatil) {
                    font-size: 10px;
                  }
                }
              }

              .submenu-custom-submenu {
                @include responsive(minportatil) {
                  position: absolute;
                  width: calc(100% - 400px);
                  padding: 50px 25% 50px 50px;
                  right: 0;
                  top: 0;
                  margin-left: 400px;
                  background-color: transparent;
                  columns: 3;
                  box-shadow: inset 2px 0 10px #0000000d;
                  height: 100%;
                  display: none;
                }

                .submenu__item {
                  @include responsive(minportatil) {
                    border-right: none;
                    width: auto;
                    color: $primary;
                  }

                  a {
                    @include responsive(minportatil) {
                      display: block;
                      font-weight: 500;
                      color: $primary;
                      font-size: 13px;

                      @include transition (all 0.25s ease-in);

                      &:hover {
                        color: #00000085;
                      }
                    }
                  }

                  &:first-of-type {
                    @include responsive(minportatil) {
                      display: block;
                    }

                    a {
                      @include responsive(minportatil) {
                        text-transform: initial;
                      }
                    }
                  }

                  &:hover {
                    @include responsive(minportatil) {
                      a {
                        opacity: 0.5;

                        @include transition (all 0.25s ease-in);
                      }
                    }
                  }
                }
              }

              &:first-of-type {
                @include responsive(minpc) {
                  border-right: none;
                }

                a {
                  @include responsive(minpc) {
                    text-transform: uppercase;
                  }
                }
              }

              a {
                font-weight: 500;

                @include responsive(minpc) {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  color: $primary;
                }

                i {
                  @include responsive(minpc) {
                    font-size: 10px;
                  }
                }
              }

              &--visible {
                .submenu-custom-submenu {
                  display: block;
                }
              }
            }
          }

          &--blog {
            min-height: initial;

            @include responsive(pc) {
              li {
                a {
                  padding: 13px 20px;
                }
              }
            }
          }
        }

        &--visible {
          .submenu {
            @include responsive(minpc) {
              display: block;
            }
          }
        }

        & > span {
          display: flex;
          align-items: center;
          width: 100%;
          font-size: 11px;
          line-height: 12px;
          position: relative;
          color: $primary;

          @include transition(all 0.3s ease-in);

          @include responsive(pantallaca) {
            font-size: 11px;
          }

          @include responsive(portatil) {
            font-size: 12px;
          }

          @include responsive(pantalla) {
            font-size: 12px;
          }

          img {
            width: 22px;
            height: 22px;
            border-radius: 50px;
            object-fit: cover;
            margin-right: 12.5px;
          }

          i {
            display: none;

            @include responsive(portatil) {
              position: absolute;
              right: 12.5px;
              top: calc(50% - 6px);
              font-size: 8px;
              color: $mid-grey;
              display: inline-block;
            }

            &.i__left {
              @include responsive(portatil) {
                right: initial;
                top: calc(50% - 15px);
                font-size: 20px;
                color: $white;
                margin-right: 12.5px;
              }
            }
          }

          strong {
            margin-left: 2.5px;
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $primary;
            transform-origin: 100% 50%;
            transform: scale3d(0, 1, 1);
            transition: transform 0.3s;

            @include responsive(portatil) {
              display: none;
            }
          }
        }

        &-background {
          display: block;

          @include responsive(pantalla) {
            display: none;
          }

          &:nth-last-child(-n+2) {
            display: none;
          }
        }

        &:hover {
          @include responsive(minpc) {
            & > span {
              color: $grey;
              cursor: pointer;

              @include transition(all 0.3s ease-in);
            }

            span::before {
              @include responsive(portatil) {
                transform-origin: 0% 50%;
                transform: scale3d(1, 1, 1);
              }
            }

            .submenu {
              @include responsive(minpc) {
                cursor: initial;
              }
            }
          }
        }
      }

      &.mm-spn--open {
        @include responsive(portatil) {
          padding-top: 100px;
        }

        @include responsive(movil) {
          padding: 12.5px 0 0 12.5px;
          padding-top: 50px;
        }
      }
    }

    &-secondary {
      display: none;

      @include responsive(minpc) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-item {
        @include responsive(minpc) {
          margin-right: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid transparent;
        }

        a {
          text-transform: capitalize;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--opened {
      @include responsive(portatil) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: white;
        z-index: 9;
        min-height: 100vh;
        margin: 0;
        padding: 100px 50px 0;
      }
    }
  }

  &__corporate {
    @include responsive(minpc) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-item {
      text-align: right;

      &:first-child {
        span {
          text-transform: initial;
          color: $black;
        }
      }

      &:last-child {
        margin-right: 0;

        @include responsive(pantalla) {
          a {
            text-transform: capitalize;
            color: $mid-grey;
            padding: 0;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }

      a {
        text-transform: capitalize;
        font-weight: 500;
      }

      span {
        a {
          @include responsive(pc) {
            padding: 0;
            color: initial;
          }
        }
      }
    }
  }

  &.fixed {
    position: fixed;
    box-shadow: 2.5px 2.5px 20px rgba(0, 0, 0, 0.05);

    @include transition (all 0.25s ease-in);

    &.header--disabled {
      @include responsive (portatil) {
        box-shadow: none;
        padding-top: 10px;
      }
    }

    .header__logo {
      margin: 3px 50px;

      @include transition (all 0.25s ease-in);

      @include responsive (pantalla) {
        margin: 3px 25px;
      }

      @include responsive (movil) {
        margin: 3px 12.5px;
      }
    }

    .header__menu-principal-item .submenu {
      @include responsive(minpc) {
        top: 22px;
      }
    }
  }

  &--disabled {
    @include responsive (portatil) {
      padding-top: 0;
      padding-bottom: 0;
      background-color: transparent;
      box-shadow: none;
      z-index: 100000000;
    }

    .header__topbar {
      @include responsive (pantalla) {
        top: 0;
      }
    }

    .custom-search {
      @include responsive (portatil) {
        opacity: 0;
      }
    }
  }
}

.bag-submenu {
  position: absolute;
  background-color: $white;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  top: 60px;
  right: 35px;
  z-index: 100000000000000;
  max-width: 400px;
  min-width: 35px;
  padding: 25px;
  display: none;

  @include responsive(movil) {
    right: 0;
    padding: 12.5px;
    width: calc(100vw - 25px);
  }

  .cnt-triangle {
    position: absolute;
    right: 10px;
    top: -20px;

    @include responsive(movil) {
      right: 45px;
    }

    @include responsive(minimovil) {
      right: 10%;
    }

    .triangle-with-shadow {
      width: 35px;
      height: 20px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.2);

      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background: $white;
        transform: rotate(45deg);
        top: 11px;
        left: 5px;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__header {
    &-quantity {
      margin: 0;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 5px;
    }
  }

  &__content {
    &-overflow-y {
      overflow-y: scroll;
      max-height: 250px;
      margin-right: -15px;
      padding-right: 15px;

      @include responsive(movil) {
        max-height: 220px;
      }

      .product-added {
        margin: 12.5px 0 12.5px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        &__link {
          display: flex;
        }

        &__img {
          border: 1px solid $secondary;
          width: 60px;
          height: 60px;
          object-fit: cover;
          padding: 5px;

          @include responsive(pc) {
            width: 50px;
            height: 50px;
          }
        }

        &__details {
          width: calc(100% - 60px);
          padding: 0 0 0 12.5px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @include responsive(pc) {
            width: calc(100% - 50px);
          }

          .cnt-title {
            &__title {
              font-size: 12px;
              margin-top: 0;
              font-weight: 500;
              margin-bottom: 5px;
              line-height: 12px;

              a {
                text-transform: initial;
              }
            }

            &__variants {
              margin-top: 0;
              font-size: 10px;
              margin-bottom: 0;
              line-height: 10px;
            }
          }

          .cnt-price {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .price {
              display: flex;
              flex-direction: column;
              font-size: 15px;
              line-height: 15px;

              .text-iva {
                color: $light-grey;
                font-size: 10px;
                line-height: 10px;
                margin-top: 5px;
              }
            }

            a {
              margin: 0;
              max-height: 15px;
              font-size: 9px;
            }
          }
        }
      }
    }

    .shipping {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 5px;

      p {
        font-weight: 500;
        margin: 0;
        font-size: 16px;
      }
    }

    .total-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      p {
        font-weight: 600;
        margin: 0;
        font-size: 16px;
      }
    }

    .btn {
      &--primary {
        width: 100%;
        display: block;
        margin: 25px 0 0;
        padding: 15px;
        font-size: 16px;
      }
    }
  }

  &--visible {
    display: block;
  }
}

.cnt-help-button {
  display: none;
  height: 50px;
  width: 50px;
  background-color: $mid-grey;
  border-radius: 100px;
  position: fixed;
  z-index: 999999999999;
  right: 12.5px;
  bottom: 12.5px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    i {
      color: $white;
      font-size: 25px;
    }
  }

  @include responsive(tablet) {
    display: inline-block;
  }
}

.mm-ocd--open ul li .submenu__item.js-has-children {
  padding: 13px 20px;
}

.page-product {
  .header {
    box-shadow: 2.5px 2.5px 20px rgba(0, 0, 0, 0.05);

    &--disabled {
      box-shadow: none;
    }
  }
}
