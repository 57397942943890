/* Common: Variables
--------------------------------------------------------------------------------------------------------------------- */

// BREAKPOINTS
$milseiscientos: 1600px;
$milcuatrocientos: 1450px;
$pantallaca: 1366px;
$portatil: 1290px;
$pantalla: 1199px;
$pantallamin: 1095px;
$pc: 991px;
$tablet: 767px;
$bigmovil: 600px;
$movil: 480px;
$minimovil: 375px;

// COLORS: Base
$primary: #000;
$primary-hover: #212121;
$primary-dark: #fbfaf9;
$secondary: #f3f2ee;
$white: #fff;
$background-grey: #f3f2ee;
$background-transparent: transparent;
$light-light-grey: #fbfaf9;
$grey: #8a8a8a;
$light-grey: #bdbdbd;
$mid-grey: #444242;
$dark-grey: #504f4f;
$black: #000;
$pink: #fde9e9;
$grey-trustivity: #8e999f;

//Academy colors
$academy-background: #eaf1f0;

// COLORS: Text
$title-color: $black;
$text-color: $black;
$post-color: $dark-grey;

// COLORS: Alerts
$success: #8ac148;
$success-hover: #599014;
$info: #00a9f4;
$info-hover: #007ac1;
$warning: #ff9800;
$warning-hover: #c66900;
$danger: #e53635;
$danger-hover: #ab000e;

// FONTS
$font-text: 'Montserrat', sans-serif;
$font-title: 'Montserrat', sans-serif;
$icon: 'icomoon' !important;
