/* Layouts: Above the fold
--------------------------------------------------------------------------------------------------------------------- */

/* START Above the fold - Product page */
.product-page__content {
  &-visual {
    width: 50%;

    @include responsive(pc) {
      width: 100%;
    }

    .product-img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 3px solid $secondary;
        margin: 0;
        padding: 50px;

        @include responsive(tablet) {
          border: none;
        }
      }
    }

    .products__slider-thumbs {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        width: 100px;
        height: 100px;
        border: 2px solid $secondary;
        margin: 10px 6.25px 0;
        padding: 0;

        @include responsive(pc) {
          width: 10px;
          height: 10px;
          border-radius: 50px;
          background-color: $white;
          border: 1px solid $mid-grey;
          overflow: hidden;
        }

        &.tns-nav-active {
          @include responsive(pc) {
            background-color: $mid-grey;
            padding: 1px;
          }
        }

        img {
          width: 100%;
          object-fit: cover;
          max-height: 100px;
          height: 100%;

          @include responsive(pc) {
            display: none;
          }
        }

        &:hover {
          @include responsive(minpc) {
            cursor: pointer;
          }
        }

        .loading {
          padding: 0;
        }
      }
    }

    .products__slider-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include responsive(pc) {
        display: none;
      }

      div {
        position: absolute;
        width: 20px;
        height: auto;
        bottom: 40px;
        left: 0;

        i {
          color: $light-grey;

          &:hover {
            @include responsive(minpc) {
              color: $primary;
              cursor: pointer;
            }
          }
        }

        &:first-child {
          i {
            display: inline-block;
            transform: rotate(-180deg);
          }
        }

        &:last-child {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          left: inherit;
          right: 0;
        }
      }
    }

    .position-relative {
      position: relative;

      .btn-zoom {
        position: absolute;
        right: 12.5px;
        top: 12.5px;
      }

      .img-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .product-img-gallery {
          margin: 0;
          padding: 0;
          min-height: 600px;
          height: 600px;

          @include responsive(tablet) {
            min-height: 450px;
            height: 450px;
          }

          @include responsive(movil) {
            min-height: 390px;
            height: 390px;
          }

          &__figure {
            margin: 0;
            height: 100%;

            img {
              object-fit: contain !important;
            }
          }
        }
      }

      &.display-custom-gallery {
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-wrap: wrap;

        @include responsive(tablet) {
          flex-wrap: wrap;
          min-height: 450px;
          height: 450px;
          overflow: hidden;
        }

        @include responsive(movil) {
          min-height: 390px;
          height: 390px;
        }

        .tns-outer {
          width: calc(100% - 275px);

          @include responsive(milcuatrocientos) {
            width: calc(100% - 225px);
          }

          @include responsive(tablet) {
            width: 100%;
          }
        }

        .products__slider-thumbs {
          width: calc(100% - 275px);

          @include responsive(milcuatrocientos) {
            width: calc(100% - 225px);
          }

          @include responsive(tablet) {
            width: 100%;
          }
        }

        .products__slider-nav {
          width: calc(100% - 275px);

          @include responsive(tablet) {
            width: 100%;
          }
        }

        & > .container-medio {
          position: absolute;
          right: 0;
          top: 0;
          width: 250px;

          @include responsive(milcuatrocientos) {
            width: 200px;
          }

          @include responsive(milcuatrocientos) {
            width: 200px;
          }
        }

        .img-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          width: calc(100% - 275px);

          @include responsive(milcuatrocientos) {
            width: calc(100% - 200px);
          }

          @include responsive(pc) {
            width: calc(100% - 275px);
          }

          @include responsive(tablet) {
            width: 100%;
          }
        }

        .container-medio {
          padding: 0;
          margin-left: 25px;
          display: flex;

          @include responsive(tablet) {
            position: absolute;
            top: 295px;
            left: 12.5px;
            margin-left: 0;
            width: 50px;
            height: 50px;
          }

          .product-video {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;

            @include responsive(tablet) {
              width: 100%;
            }

            &__video {
              width: 100%;
              height: auto;
              display: flex;

              @include responsive(tablet) {
                height: 100%;
              }

              &-image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                position: relative;
                overflow: hidden;
                border: 3px solid $secondary;
                margin-bottom: 25px;

                @include responsive(tablet) {
                  border: none;
                  margin-bottom: 0;
                  height: 100%;
                }

                &::before {
                  content: "";
                  width: 100%;
                  position: absolute;
                  padding-top: 100%;
                  z-index: 2;

                  @include responsive(tablet) {
                    padding-top: 0;
                  }
                }

                img {
                  width: auto;
                  display: flex;
                  height: 250px;
                  z-index: 2;

                  @include responsive(milcuatrocientos) {
                    height: 200px;
                  }

                  @include responsive(tablet) {
                    opacity: 0;
                  }
                }

                .iconplay {
                  position: absolute;
                  width: 60px;
                  height: 60px;
                  left: calc(50% - 30px);
                  top: calc(50% - 30px);
                  font-size: 40px;
                  color: $white;
                  background: $white;
                  border-radius: 100%;
                  padding: 5px;
                  z-index: 3;
                  transition: all 0.5s ease-in-out;

                  .stroke-solid {
                    stroke-dashoffset: 0;
                    stroke-dasharray: 300;
                    stroke-width: 4px;
                    transition: stroke-dashoffset 1s ease, opacity 1s ease;
                  }

                  .icon {
                    transform: scale(0.8);
                    transform-origin: 50% 50%;
                    transition: transform 200ms ease-out;
                  }

                  &:hover {
                    background: rgba(255, 255, 255, 0.75);
                    transition: all 0.5s ease-in-out;
                    cursor: pointer;
                  }

                  @keyframes spin {
                    to {
                      transform: rotate(360deg);
                    }
                  }
                }
              }
            }

            &__text {
              width: 100%;
              border: 3px solid $secondary;
              overflow: hidden;
              max-width: 256px;

              @include responsive(tablet) {
                display: none;
              }

              .content {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                i {
                  position: absolute;
                  top: calc(50% - 15px);
                  left: calc(50% - 15px);
                  font-size: 30px;
                  color: #fff;

                  &:hover {
                    @include responsive(minpc) {
                      color: $primary;
                      cursor: pointer;
                    }
                  }
                }

                img {
                  width: 100%;
                  display: flex;
                  height: 250px;
                  object-fit: cover;

                  @include responsive(milcuatrocientos) {
                    height: 200px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .js-product-page__content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12.5px;

    .title {
      text-transform: initial;
      color: $mid-grey;
      font-size: 30px;
      font-weight: 500;

      @include responsive(pc) {
        font-size: 25px;
        margin-right: 5px;
      }
    }

    .wishlist {
      i {
        font-size: 20px;
        margin-top: 4px;
        padding: 4px;
        border-radius: 100px;
        display: inline-block;

        &:hover {
          @include responsive(minpc) {
            background-color: $primary;
            color: $white;

            @include transition(all 0.45s ease-in);
          }
        }
      }
    }
  }
}

/* END Above the fold - Product page */
