/* Layouts: Pages: Home
--------------------------------------------------------------------------------------------------------------------- */
.home {
  margin: 100px 50px 50px;
  width: calc(100% - 100px);

  @include responsive(pantallamin) {
    width: calc(100% - 50px);
    margin: 50px 25px;
  }

  @include responsive(tablet) {
    width: calc(100% - 50px);
    margin: 50px 25px;
  }

  @include responsive(movil) {
    width: calc(100% - 25px);
    margin: 50px 12.5px;
  }

  &__slider {
    position: relative;
    margin: 0 50px;
    max-height: 550px;
    overflow: hidden;

    @include responsive(milseiscientos) {
      max-height: 430px;
      min-height: 430px;
    }

    @include responsive(pantalla) {
      width: calc(100% - 50px);
      margin: 0 25px;
    }

    @include responsive(pc) {
      width: 100%;
      margin: 0;
    }

    @include responsive(tablet) {
      margin-bottom: 40px;
      max-height: 300px;
      min-height: 300px;
    }

    .slide {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      min-height: 400px;
      position: relative;

      @include responsive(pc) {
        flex-direction: column;
      }

      @include responsive(tablet) {
        min-height: 300px;
      }

      &-content {
        padding: 80px 30px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        height: 100%;
        width: 100%;

        @include responsive(milseiscientos) {
          width: 100%;
        }

        @include responsive(movil) {
          padding: 12.5px;
        }

        .title,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 34px;
          font-family: $font-title;
          text-transform: uppercase;
          margin: 0;
          line-height: 40px;
          z-index: 8;
          width: 32%;
          color: $primary;

          @include responsive(pantalla) {
            font-size: 30px;
            line-height: 36px;
            width: 25%;
          }

          @include responsive(tablet) {
            width: 100%;
          }

          @include responsive(movil) {
            font-size: 25px;
            line-height: 30px;
          }
        }

        .text,
        p {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 25px;
          z-index: 8;
          width: 32%;
          color: $primary;
          font-weight: 500;

          @include responsive(tablet) {
            width: 100%;
          }

          @include responsive(movil) {
            margin-right: 12.5px;
          }
        }

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }

        .btn {
          z-index: 2;
          font-size: 13px;
          padding: 12px 26px;
          letter-spacing: 2px;
          font-weight: 500;

          &--primary {
            display: inline-block;
          }
        }

        &--playing {
          display: none;
        }
      }

      &-visual {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        max-height: 550px;
        min-height: 550px;

        @include responsive(milseiscientos) {
          max-height: 430px;
          min-height: 430px;
        }

        @include responsive(tablet) {
          max-height: 300px;
          min-height: 300px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .iconplay {
          position: absolute;
          top: calc(50% - 25px);
          right: calc(50% - 25px);
          width: 50px;
          height: 50px;
          color: $black;
          z-index: 8;

          @include responsive(bigmovil) {
            top: initial;
            bottom: 6px;
            right: 6px;
          }

          .stroke-solid {
            stroke-dashoffset: 0;
            stroke-dasharray: 300;
            stroke-width: 4px;
            transition: stroke-dashoffset 1s ease, opacity 1s ease;
          }

          .icon {
            transform: scale(0.8);
            transform-origin: 50% 50%;
            transition: transform 200ms ease-out;
          }

          &:hover {
            color: $primary;

            .stroke-solid {
              opacity: 1;
              stroke-dashoffset: 300;
            }

            .icon {
              transform: scale(0.9);
            }
          }

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        }

        .visual {
          object-fit: cover;
          min-width: 100%;
          height: 100%;
          max-height: 550px;
          min-height: 550px;
          position: absolute;
          top: 0;
          left: 0;

          @include responsive(milseiscientos) {
            max-height: 430px;
            min-height: 430px;
          }

          @include responsive(tablet) {
            max-height: 300px;
            min-height: 300px;
          }

          &-video {
            display: none;
          }
        }

        iframe {
          position: absolute;
          top: -25%;
          left: 0;

          @include responsive(milseiscientos) {
            max-height: 430px;
            min-height: 430px;
          }

          @include responsive(tablet) {
            max-height: 300px;
            min-height: 300px;
          }

          &-video {
            display: none;
          }
        }

        &::after {
          content: "";
          float: left;
          width: 100%;
          padding-top: 56.25%;
        }

        &--playing {
          max-height: inherit;
          min-height: inherit;

          & > .visual-img {
            display: none;
          }

          & > .visual-video {
            display: block;
          }

          & > a {
            display: none;
          }

          &::before {
            background: transparent;
          }

          iframe {
            object-fit: inherit;
            max-height: inherit;
            min-height: inherit;
            height: 100%;
          }
        }
      }
    }

    &-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: auto;
      height: 10px;
      position: absolute;
      bottom: 15px;
      left: calc(50% - 27px);
      z-index: 8;

      @include responsive(tablet) {
        bottom: -20px;
      }

      div {
        &:first-child {
          margin-right: 10px;

          i {
            transform: rotate(180deg);
          }
        }

        i {
          display: inline-block;
          border-radius: 100px;
          padding: 4px;
          color: $primary;

          &:hover {
            @include responsive(minpc) {
              background: $primary;
              color: $white;
              cursor: pointer;

              @include transition(all 0.45s ease-in);
            }
          }
        }
      }
    }
  }

  &__ofrecer {
    display: flex;

    @include responsive(pantallaca) {
      flex-wrap: wrap;
    }

    @include responsive(pantallamin) {
      flex-direction: column;
      width: calc(100% - 25px);
      margin: 25px 0 0 25px;
    }

    @include responsive(movil) {
      margin: 25px 0 0 12.5px;
      width: calc(100% - 12.5px);
    }

    .left {
      width: 25%;

      @include responsive(pantallaca) {
        width: 70%;
        margin-bottom: 50px;
      }

      @include responsive(pantallamin) {
        width: 70%;
        margin-bottom: 0;
      }

      @include responsive(movil) {
        width: 100%;
      }

      .title {
        font-size: 25px;

        @include responsive(movil) {
          font-size: 20px;
        }
      }
    }

    .right {
      width: calc(75% - 25px);
      margin-left: 25px;
      display: flex;

      @include responsive(pantallaca) {
        width: 100%;
        display: flex;
      }

      @include responsive(pantallamin) {
        display: initial;
        align-items: initial;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        margin-left: 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        display: flex;
        align-items: center;

        @include responsive(pantallamin) {
          width: 60%;
          display: inline-flex;
          margin-right: 5%;
          overflow: hidden;
          padding: 25px 0;
        }

        @include responsive(bigmovil) {
          width: 80%;
        }

        @include responsive(movil) {
          width: 85%;
        }

        .cnt-img {
          position: relative;

          .img {
            border-radius: 100%;
            margin-right: 20px;
            width: 80px;
            height: 80px;
            object-fit: cover;

            @include responsive(pantallamin) {
              margin-right: 0;
              width: 90px;
              height: 90px;
            }

            @include responsive(movil) {
              width: 80px;
              height: 80px;
            }
          }

          .decorative {
            position: absolute;
            width: 60px;
            z-index: -1;

            @include responsive(pantallamin) {
              width: 50px;
            }

            &--0 {
              top: -20px;
              left: -20px;
            }

            &--1 {
              bottom: -8px;
              left: -5px;
            }

            &--2 {
              right: 25px;
              top: -20px;
            }
          }
        }

        .cnt-text {
          margin: 0 12.5px;

          .title {
            font-size: 15px;
            color: $primary;
            margin-top: 0;
            margin-bottom: 5px;
            font-weight: 600;

            @include responsive(pantallamin) {
              white-space: break-spaces;
              max-width: 85%;
            }

            @include responsive(movil) {
              font-size: 14px;
              max-width: 95%;
            }
          }

          .text {
            margin: 0;
            font-size: 14px;

            @include responsive(pantallamin) {
              white-space: break-spaces;
            }

            @include responsive(movil) {
              font-size: 12px;
            }
          }
        }

        &:nth-child(2) {
          .cnt-img {
            .decorative {
              top: initial;
              left: 0;
              bottom: -15px;
            }
          }
        }

        &:last-child {
          .cnt-img {
            .decorative {
              left: initial;
              right: 10px;
            }
          }
        }
      }
    }
  }

  &__categories {
    display: flex;

    @include responsive(pantalla) {
      flex-wrap: wrap;
    }

    @include responsive(tablet) {
      margin: 0 25px 50px;
      width: calc(100% - 50px);
      flex-direction: column;
    }

    @include responsive(movil) {
      margin: 0 12.5px 50px;
      width: calc(100% - 25px);
      flex-direction: column;
    }

    .left {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 25px);
      margin-right: -25px;

      @include responsive(pantalla) {
        width: calc(100% + 12.5px);
        margin-right: -12.5px;
      }

      .category {
        width: calc(100% / 4 - 25px);
        margin-right: 25px;
        margin-bottom: 25px;
        position: relative;
        overflow: hidden;
        background-color: $pink;

        @include transition(all 0.45s ease-in);

        @include responsive(pantalla) {
          width: calc(100% / 3 - 12.5px);
          margin-right: 12.5px;
          margin-bottom: 12.5px;
        }

        @include responsive(tablet) {
          width: calc(100% / 2 - 12.5px);
          margin-right: 12.5px;
          margin-bottom: 12.5px;
        }

        &--img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 1;

          @include transition (all 0.45s ease-in);

          &_hover {
            opacity: 0;
            position: absolute;
            right: -50px;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right center;

            @include transition (all 0.45s ease-in);
          }
        }

        &--title {
          position: absolute;
          bottom: 25px;
          left: 25px;
          font-size: 15px;
          color: $white;

          @include transition (all 0.2s ease-in);

          @include responsive(tablet) {
            bottom: 12.5px;
            left: 20px;
          }

          @include responsive(movil) {
            bottom: 6.25px;
            left: 6.25px;
          }

          i {
            @include responsive(pantalla) {
              display: none;
            }
          }
        }

        &:hover {
          @include responsive(minpc) {
            cursor: pointer;
          }

          @include transition (all 0.2s ease-in);

          .category--title {
            @include responsive(minpc) {
              color: $black;

              @include transition (all 0.2s ease-in);
            }
          }

          .category--img {
            @include responsive(minpc) {
              opacity: 0;

              @include transition (all 0.45s ease-in);
            }

            &_hover {
              @include responsive(minpc) {
                opacity: 1;

                @include transition (all 0.45s ease-in);
              }
            }
          }
        }
      }
    }

    .right {
      width: 20%;
      background-color: $secondary;

      @include responsive(milseiscientos) {
        width: 30%;
      }

      @include responsive(pantalla) {
        width: 40%;
      }

      @include responsive(pantallamin) {
        width: 50%;
      }

      @include responsive(tablet) {
        width: 100%;
        margin-top: 12.5px;
      }

      .kit {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        height: 100%;

        &--wishlist {
          position: absolute;
          right: 20px;
          top: 26px;
          width: 18px;
          height: 18px;

          @include responsive(tablet) {
            right: 10px;
          }

          i {
            font-size: 18px;
          }
        }

        &--title {
          padding: 20px 20px 0;
          line-height: 30px;
          width: 100%;

          @include responsive(tablet) {
            font-size: 27px;
            padding: 20px 10px 0;
          }

          @include responsive(movil) {
            font-size: 24px;
          }
        }

        &--text {
          padding: 0 20px;
          margin-top: 0;
          margin-bottom: 20px;
          width: 100%;

          @include responsive(movil) {
            font-size: 13px;
          }

          @include responsive(tablet) {
            margin-top: 5px;
            padding: 0 10px;
          }
        }

        &--img {
          width: calc(100% - 8px);
          object-fit: cover;
          margin: 0 4px;
          border-bottom: none;

          @include responsive(tablet) {
            margin-left: 10px;
            margin-right: 10px;
          }

          @include responsive(movil) {
            width: calc(100% - 20px);
          }
        }

        &--cart {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .btn {
            &--primary {
              @include responsive(tablet) {
                width: 100%;
              }

              &:hover {
                @include responsive(minpc) {
                  background-color: $primary-hover;
                  color: $white;
                  border: 1px solid $primary-hover;
                }
              }
            }
          }
        }

        .price {
          padding-left: 20px;

          @include responsive(tablet) {
            padding-left: 20px;
            position: absolute;
            top: 50px;
            right: 10px;
          }
        }
      }
    }
  }

  &__banner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    @include responsive(tablet) {
      flex-direction: column;
    }

    .banner {
      width: calc(50% - 25px);
      display: flex;
      align-items: center;
      position: relative;

      @include responsive(pantalla) {
        width: 100%;
      }

      @include responsive(tablet) {
        align-items: stretch;
        width: calc(100%);
        margin-bottom: 50px;
        flex-wrap: wrap;
        min-height: 375px;
      }

      @include responsive(bigmovil) {
        min-height: 300px;
      }

      &:first-of-type {
        @include responsive(pantalla) {
          margin-bottom: 50px;
        }
      }

      &--left {
        width: 40%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background-color: $secondary;
        padding: 30px;

        @include responsive(pantalla) {
          width: 50%;
        }

        @include responsive(tablet) {
          height: auto;
          width: 100%;
          align-items: stretch;
        }

        @include responsive(movil) {
          padding: 12.5px;
        }

        &_top {
          height: 100%;

          @include responsive(tablet) {
            width: 100%;
          }

          .text {
            @include responsive(tablet) {
              font-size: 16px;
            }
          }

          .title {
            @include responsive(movil) {
              font-size: 25px;
            }
          }
        }

        .btn {
          &--underline {
            bottom: 30px;

            @include responsive(tablet) {
              bottom: 50px;
            }
          }
        }
      }

      &--right {
        width: 60%;
        height: 100%;
        position: relative;
        overflow: hidden;

        @include responsive(pantalla) {
          width: 50%;
        }

        @include responsive(tablet) {
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          height: auto;
          max-width: 60%;
          z-index: 1;
        }

        @include responsive(bigmovil) {
          max-width: 55%;
        }

        .img {
          width: 100%;
          object-fit: cover;
          height: 100%;

          @include transition (all 0.45s ease-in);
        }
      }

      .decoration {
        position: absolute;
        right: -35px;
        top: -45px;
        width: 120px;
        z-index: 7;

        @include responsive(pantallamin) {
          right: -25px;
        }

        @include responsive(tablet) {
          right: -12.5px;
        }

        @include responsive(movil) {
          right: 0;
        }

        &--custom {
          right: 0;
          width: 100px;
        }
      }
    }
  }

  &__ratings {
    position: relative;
    width: 100%;
    padding: 50px;
    margin: 0;
    overflow: hidden;

    @include responsive(tablet) {
      padding: 50px 25px;
    }

    @include responsive(movil) {
      padding: 50px 12.5px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include responsive(movil) {
        flex-direction: column;
      }

      .cnt-text {
        max-width: 25%;

        @include responsive(milseincientos) {
          max-width: 35%;
        }

        @include responsive(tablet) {
          max-width: 50%;
          width: 50%;
          margin-bottom: 20px;
        }

        @include responsive(movil) {
          max-width: 100%;
          width: 100%;
        }

        .title {
          display: block;

          @include responsive(tablet) {
            font-size: 25px;
            display: none;
          }

          &--mobile {
            display: none;

            @include responsive(tablet) {
              display: block;
            }
          }
        }

        .text {
          margin-bottom: 0;

          @include responsive(tablet) {
            display: none;
          }
        }

        .btn {
          &--mobile {
            display: none;
            margin-top: 15px;

            @include responsive(tablet) {
              display: inline-block;
            }
          }
        }
      }

      .cnt-info {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        @include responsive(tablet) {
          width: 50%;
          text-align: right;
          margin-bottom: 20px;
        }

        @include responsive(movil) {
          width: 100%;
          text-align: left;
          align-items: flex-start;
        }

        .text {
          @include responsive(tablet) {
            font-size: 14px;
          }

          i {
            color: $primary;

            @include responsive(tablet) {
              display: none;
            }
          }

          &:first-child {
            margin: 0;
          }

          &--primary {
            color: $primary;
            font-size: 20px;
            font-weight: 600;
            margin-top: 5px;

            @include responsive(tablet) {
              font-size: 14px;
              margin-bottom: 0;
            }

            span {
              font-size: 30px;

              @include responsive(tablet) {
                font-size: 20px;
              }
            }
          }
        }

        .btn {
          display: block;

          @include responsive(tablet) {
            display: none;
          }
        }
      }
    }

    .decoration-right {
      position: absolute;
      right: -15%;
      top: 0;
      width: 30%;
      z-index: -1;
      transform: rotate(4deg);
      transform-origin: top center;
      animation: swing 25s ease infinite;

      @include responsive(milseiscientos) {
        width: 40%;
      }

      @include responsive(tablet) {
        right: -27%;
        top: 10%;
        width: 100%;
        transform: rotate(1deg);
      }
    }

    .decoration-left {
      position: absolute;
      left: -4%;
      bottom: 2%;
      width: 18%;
      z-index: -1;

      @include responsive(tablet) {
        display: none;
      }

      .animation__lines {
        background-color: transparent;
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        text-align: center;
        opacity: 1;
      }
    }

    &-slider {
      margin: 25px 0;
    }

    &-nav {
      display: flex;
      align-items: center;
      justify-content: center;

      & > div {
        &:first-child {
          i {
            margin-right: 12.5px;
            transform: rotate(180deg);
          }
        }

        i {
          display: inline-block;
          border-radius: 100px;
          padding: 4px;

          &:hover {
            @include responsive(minpc) {
              background: $primary;
              color: $white;
              cursor: pointer;

              @include transition(all 0.45s ease-in);
            }
          }
        }
      }
    }

    .rating--stars {
      display: flex;
      align-items: center;

      .text {
        margin-right: 12.5px;
      }
    }
  }

  &__blogs {
    display: flex;
    position: relative;
    width: 100%;
    margin: 0 0 100px;
    padding: 30px 0;
    overflow: hidden;

    @include responsive(pc) {
      flex-direction: column;
      padding: 0;
    }

    @include responsive(movil) {
      margin: 0 0 50px;
    }

    &::before {
      content: "";
      width: 75%;
      height: 100%;
      position: absolute;
      background-color: $secondary;
      top: 0;
      right: 0;
      z-index: -3;

      @include responsive(pc) {
        width: 100%;
      }
    }

    .left {
      width: 50%;
      position: relative;
      padding-left: 50px;
      margin-right: 30px;
      display: flex;
      flex-wrap: wrap;
      padding-top: 25px;

      @include responsive(pc) {
        min-height: 350px;
        width: 100%;
        padding-left: 25px;
      }

      @include responsive(movil) {
        padding-left: 12.5px;
      }

      &::before {
        content: "";
        position: absolute;
        left: -50px;
        top: 0;
        background-image: url('/themes/granvelada/assets/img/gran-velada-academy.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: calc(100% + 50px);
        height: 100%;
        z-index: -2;

        @include responsive(pc) {
          background-position: left top;
        }
      }

      .container-custom {
        width: 35%;

        @include responsive(bigmovil) {
          width: 60%;
        }

        @include responsive(movil) {
          width: 70%;
        }

        .title {
          @include responsive(movil) {
            font-size: 25px;
          }
        }
      }
    }

    .right {
      width: calc(50% - 50px);
      margin: 15px 50px 15px 0;

      @include responsive(pc) {
        width: calc(100% - 25px);
        margin-left: 25px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      @include responsive(movil) {
        width: calc(100% - 12.5px);
        margin-left: 12.5px;
      }

      .title {
        margin-bottom: 30px;

        @include responsive(pc) {
          margin-top: 30px;
        }

        @include responsive(movil) {
          margin-top: 12.5px;
          margin-bottom: 12.5px;
          width: 100%;
          font-size: 25px;
        }

        &:nth-of-type(2) {
          margin-top: 30px;

          @include responsive(pc) {
            width: 50%;
          }

          @include responsive(movil) {
            margin-top: 12.5px;
            width: 100%;
          }
        }
      }

      .blogs {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include responsive(pc) {
          display: initial;
          justify-content: initial;
          width: 100%;
          overflow-x: scroll;
          overflow-y: hidden;
          white-space: nowrap;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        &--item {
          width: calc(20% - 8px);
          margin-right: 10px;
          overflow: hidden;

          @include responsive(pc) {
            display: inline-block;
            width: 27.5%;
          }

          @include responsive(movil) {
            width: 35%;
          }

          &:last-child {
            margin-right: 0;

            @include responsive(pc) {
              margin-right: 10px;
            }
          }

          .item--product--image {
            overflow: hidden;
            display: flex;

            .product--image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              transform: scale(1);
              padding: 0;
              border: none;

              @include transition (all 0.2s ease-in);
            }

            &:hover {
              .product--image {
                @include responsive(minpc) {
                  transform: scale(1.2);
                }
              }
            }
          }

          .title {
            font-family: $font-title;
            color: $primary;
            text-transform: uppercase;
            font-size: 13px;
            margin-top: 10px;
          }

          &:hover {
            .title {
              @include responsive(minpc) {
                opacity: 0.5;

                @include transition (all 0.2s ease-in);
              }
            }
          }
        }
      }

      .social {
        @include responsive(pc) {
          width: 50%;
        }

        @include responsive(movil) {
          width: 100%;
        }

        & > a {
          &:hover {
            opacity: 0.5;
            color: $primary;
          }
        }

        i {
          font-size: 30px;
          margin-right: 30px;
          color: $primary;

          @include responsive(movil) {
            font-size: 25px;
          }

          @include responsive(pc) {
            margin-right: 10px;
          }

          &:hover {
            color: $mid-grey;
          }
        }
      }
    }

    .decorative {
      position: absolute;
      right: -30%;
      top: -42%;
      width: 45%;
      z-index: -1;
      transform: rotate(300deg);
      opacity: 0;

      @include responsive(pc) {
        right: -80%;
        top: initial;
        width: 115%;
        bottom: -20%;
        z-index: -3;
      }
    }

    &--animated {
      .decorative {
        opacity: 1;

        path {
          stroke-dasharray: 10000;
          stroke-dashoffset: 10000;
          animation: dash 10s linear forwards;
        }
      }
    }
  }

  &__youtube {
    margin: 100px 0 0;
    width: 100%;

    @include responsive(pantalla) {
      margin: 50px 0 50px;
    }

    .youtube-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 50px 25px;

      @include responsive(pantalla) {
        margin: 0 25px 25px;
      }

      @include responsive(movil) {
        margin: 0 12.5px 25px;
      }

      .left {
        max-width: 25%;

        @include responsive(tablet) {
          max-width: 35%;
        }

        @include responsive(tablet) {
          max-width: 100%;
        }

        .text {
          margin-bottom: 0;
        }

        .title {
          @include responsive(movil) {
            font-size: 25px;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > a {
          &:hover {
            opacity: 0.5;
          }
        }

        i {
          font-size: 30px;
          color: $primary;
        }

        .text {
          display: block;
          text-align: right;
          margin-top: 15px;

          a {
            display: block;
            color: $primary;
            text-transform: initial;
            font-size: 16px;
          }
        }

        @include responsive(tablet) {
          display: none;
        }
      }
    }

    &-slider {
      &-item {
        overflow: hidden;
        position: relative;

        .cover-youtube {
          width: 100%;
          object-fit: cover;
          min-height: 300px;
          display: flex;

          @include transition(all 0.34s ease-in);

          @include responsive(milseiscientos) {
            min-height: 350px;
          }

          @include responsive(minpc) {
            min-height: 300px;
          }

          @include responsive(pc) {
            min-height: 250px;
          }

          @include responsive(movil) {
            min-height: 150px;
          }
        }

        iframe {
          display: none;
          min-height: 300px;
          object-fit: cover;
          width: 100%;

          @include responsive(minpc) {
            min-height: 350px;
          }

          @include responsive(pc) {
            min-height: 250px;
          }

          @include responsive(movil) {
            min-height: 150px;
          }
        }

        i {
          position: absolute;
          font-size: 40px;
          top: calc(50% - 20px);
          right: calc(50% - 5px);
          color: $white;

          &:hover {
            @include responsive(minpc) {
              color: $primary;
              cursor: pointer;
            }
          }
        }

        &--playing {
          .cover-youtube {
            display: none;
          }

          iframe {
            display: block;
          }

          i {
            display: none;
          }
        }

        &_overflow {
          display: flex;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0);
          width: calc(100% - 50px);
          height: 100%;
          justify-content: center;
          align-items: center;
          z-index: 9;

          @include transition(all 0.35s ease-in);

          span {
            color: $white;
            text-decoration: underline;
            text-transform: uppercase;
            opacity: 0;
          }
        }

        &:hover {
          @include responsive(minpc) {
            cursor: pointer;
          }

          .home__youtube-slider-item_overflow {
            @include responsive(minpc) {
              background-color: rgba(0, 0, 0, 0.75);

              @include transition(all 0.34s ease-in);
            }

            span {
              @include responsive(minpc) {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &-nav {
      margin: 12.5px 50px 0;
      display: flex;
      align-items: center;

      @include responsive(pantalla) {
        margin: 12.5px 25px 0;
      }

      @include responsive(movil) {
        margin: 12.5px 12.5px 0;
      }

      > div {
        &:first-child {
          i {
            display: inline-block;
            transform: rotate(180deg);
            margin-right: 12.5px;
          }
        }

        i {
          &:hover {
            @include responsive(minpc) {
              color: $primary;
              cursor: pointer;
            }
          }
        }
      }
    }

    &-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px;
    }
  }

  &__text {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 100px 100px;
    width: 100%;
    columns: 2;
    column-gap: 50px;

    @include responsive(pantallamin) {
      padding: 0 25px;
      width: 100%;
      columns: 1;
    }

    @include responsive(movil) {
      padding: 0 12.5px;
    }

    .left {
      width: calc(100% - 75px);
      margin: 0 25px 0 50px;

      @include responsive(pantallamin) {
        width: 100%;
        margin: 0;
      }
    }

    .right {
      width: calc(100% - 75px);
      margin: 0 25px 0 50px;

      @include responsive(pantallamin) {
        width: 100%;
        margin: 0 0 25px;
      }
    }

    .title,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 14px;
      color: $primary;
      line-height: 20px;
      font-family: $font-title;
      font-weight: 700;

      @include responsive(pc) {
        border-bottom: 1px solid $light-grey;
        margin-bottom: 15px;
        padding-bottom: 12.5px;
        position: relative;
      }

      &::after {
        @include responsive(pc) {
          content: "\e908";
          transform: rotate(90deg);
          font-family: 'icomoon', sans-serif;
          position: absolute;
          right: 25px;
          font-size: 14px;
          top: 0;
        }

        @include responsive(movil) {
          right: 12.5px;
        }
      }

      &--opened {
        @include responsive(pc) {
          margin-bottom: 0;
        }

        &::after {
          @include responsive(pc) {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .texto,
    p {
      font-size: 13px;

      @include responsive(pc) {
        display: none;
      }

      &--opened {
        @include responsive(pc) {
          display: block;
        }
      }
    }

    .decorative {
      position: absolute;
      right: -30%;
      top: 0;
      width: 50%;
      transform: rotate(15deg);
      z-index: -1;
      opacity: 0;

      @include responsive(milseiscientos) {
        right: -40%;
        top: -2%;
        width: 70%;
      }

      @include responsive(pantallamin) {
        display: none;
      }
    }

    a {
      text-transform: initial;
    }

    &--animated {
      .decorative {
        opacity: 0.25;

        path {
          stroke-dasharray: 10000;
          stroke-dashoffset: 10000;
          animation: dash 20s linear forwards;
        }
      }
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes swing {
  from,
  to { transform: scale(1, 1); }

  20% { transform: rotate(2deg); }

  25% { transform: scale(0.95, 1.05); }

  40% { transform: rotate(-2deg); }

  50% { transform: scale(1.05, 0.95); }

  60% { transform: rotate(2deg); }

  75% { transform: scale(0.95, 1.05); }

  80% { transform: rotate(-2deg); }

  100% { transform: rotate(2deg); }
}
